.list-details-tabs .nav-tabs .nav-link.active {
    font-weight: 700;
    color: #000;
}

.list-details-tabs .nav-tabs .nav-link {
    color: #fff;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link {
    color: #000;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.profile-tabs .nav-tabs .nav-item,
.list-details-tabs .nav-tabs .nav-item,
.list-details-cus-tabs .nav-tabs .nav-item {
    border-radius: 0px;
    min-width: 150px;
    text-align: center;
}

.picture {
    display: inline-block;
    width: 130px;
}

.bg-custom {
    background-color: #404e67 !important;
}

.fade {
    transition: opacity 0.15s linear;
}

.org-container {
    width: 75%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

/* a:not([href]) {
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
}
a:not([href]) {
  color: inherit;
  text-decoration: none;
} */
label {
    font-weight: 600;
}

.form-control {
    /*padding: 3px 10px !important;
    font-size: inherit !important;
    height: auto !important;*/
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
    border: 0px;
}
