@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css');


.containData {
    font-family: 'poppins'
}

.containData body {
    font-family: 'poppins', sans-serif !important;
    font-size: 12px !important;
    font-weight: 400;
    background: #EEF0F2;
}

.containData a {
    text-decoration: none;
}

/* ****** GLOBAL **** */
.containData .btn,
.form-control,
.form-select {
    font-size: 0.75rem;
}

.containData .form-control,
.form-select {
    padding: 0.475rem 0.75rem;
}

.containData .btn-danger,
.containData .btn-success,
.containData .btn,
.containData .form-control,
.containData .form-select,
.containData .modal-content,
.containData .sec-wrapper {
    border-radius: 4px;
}

.containData .borderless-data .table tr td {

    color: #000;

    font-weight: 500;

    vertical-align: middle;

}

.containData .text-black,
.containData .text-dark {
    color: #000 !important;
}

.containData .bg-danger-light,
.containData .bg-success-light {
    border-radius: 0px;
}

.containData .sec-wrapper-one .compare-data {
    min-height: 150px;
}

.containData .bg-success-light {
    background: #15C39A36;
    border: #15C39A 1px solid;
    color: #000;
}

.containData .bg-danger-light,
.bg-danger-light {
    background: #E21E1E33;
    border: #db7373 1px solid;
    color: #000;
}

.containData .btn-danger {
    border: #E21E1E;
    background: #E21E1E;
}

.containData .btn-success {
    border: #15C39A;
    background: #15C39A;
    height: 30px;
}

.containData .bg-danger,
.bg-success {
    border-radius: 0;
}

.containData .btn-compare {
    background: #fff;
    border: #cad7dd 1px solid;
    color: #000;
    font-weight: 400;
    margin: 0px 6px 0px 6px;
    min-width: 150px;
    text-align: left;
    height: 30px;
}

.containData .btn-compare:hover {
    background-color: #e9c0c0 !important;
    border-color: #656565 !important;
}

.containData .bg-success-light,
.containData .bg-danger-light {
    font-size: 11px;
    font-weight: 500;
    border-radius: 3px;
}

.containData .form-control:focus,
.containData .form-select:focus {
    border-color: rgb(240 189 189);
    box-shadow: 0 0 0 0.25rem rgb(237 94 94 / 10%);
}

.containData .text-success {
    color: #15C39A !important;
}

.containData .text-black {
    color: #000 !important;
}


.containData .table>:not(caption)>*>* {
    padding: 0.4rem 0.4rem;
}

.containData .table td a {
    text-decoration: underline;
    color: inherit;
}

.containData .sec-wrapper {
    background: #fff;
    -webkit-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.34);
    box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.34);
}

.containData .sec-wrapper-one {
    background: #fff;
    border: 1px solid #F3F3F3;
    -webkit-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.34);
    box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.34);
}

.containData .sec-wrapper hr {
    border-color: #878787;
}

.containData .form-label {
    font-weight: 500;
}


.containData .input-group input {
    border-right: 0;
}

.containData .input-group .input-group-text {
    background-color: transparent;
}

.containData .input-group .input-group-text i {
    color: #8F9092;
    font-size: 11px;
}

/* DASHBOARD FEATURES CARDS ********* */

.containData .card-features {
    position: relative;
}

.containData .card-features a {
    color: #000000;
}

.containData .card-features .card {
    margin-bottom: 20px;
    border: none;
    background: #fff;
    -webkit-box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.06);

}

.containData .card-features .card .card-body {
    padding: 0.5rem;
}

.containData .card-features .card:hover,
.card-features .card.active {
    background: #C1E4DC;
}

.containData .card-features .card:hover .feature-icon img {
    background: #fff;
}

.containData .card-features .card.active .feature-icon img {
    background: #E6F8F4;
}

.containData .card-features .product-pop span.product-pop-label {
    background: #000000;
    left: auto;
    right: 8px;
    top: 8px;
    position: absolute;
}

.containData .card-features .product-pop span.product-pop-label {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    display: block;
    text-align: center;
}

.containData .card-features .feature-icon img {
    padding: 12px;
    background: #F3F7FA;
    border-radius: 4px;
    width: 64px;
    height: 64px;
}

.containData .card-features .card .card-body {
    font-size: 12px;
    padding: 15px;
    height: auto;
}

.containData .card-features .card-body .text-wrapper {
    margin-left: 20px;
    line-height: 12px;
}

.containData .card-features .card-body .text-wrapper p.count {
    font-size: 24px;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 2px;
}

.containData .card-features .card-body .text-wrapper span {
    font-size: 11px;
    font-weight: 500 !important;
    font-family: 'Poppins', sans-serif !important;
}

.containData .card-features .card-body .text-wrapper span .w400 {
    font-weight: 400;
}


/* *** TABLE ******* */
.data-table .table-title {
    font-size: 12px;
    font-weight: 600;
}

.has-search .form-control {
    padding-left: 2.375rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    height: 2.375rem !important;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

/* ******** TABLE NAV ICONS ****** */
.data-table .grid-nav img {
    width: 22px;
    height: 22px;
}

.data-table .grid-nav .btn {
    padding: 0.41rem 0.75rem;
}

.data-table .grid-nav .btn:hover {
    background: rgb(233 236 239)
}



/* ********** PAGENATION ***** */
.data-table ul.pagination {
    margin-bottom: 0;
}

.data-table ul.pagination a.page-link {
    font-size: 12px;
}

.data-table .page-item:first-child .page-link {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.data-table .page-item:last-child .page-link {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}


@media (min-width: 1400px) {
    .containData .container {
        max-width: 1920px !important;
    }

    .containData .m-b-0 {
        margin-bottom: 0 !important;
    }
}




@media (max-width: 600px) {
    .containData .m-m-b-15 {
        margin-bottom: 15px;
    }
}


/* **** DETAILS CARD **** */
.containData .card-details .card,
.containData .card-details .card.action {
    margin-bottom: 20px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.06);
}

.containData .card-features .card .card-body {
    padding: 0.5rem
}

.containData .card-details .card {
    border: none;
}

.containData .card-details .card .card-header {
    color: #000000;
    background-color: #D2E3E2;
}

.containData .card-details .card .card-header,
.card-details .card.action-green .card-header,
.containData .card-details .card.action-red .card-header {
    font-weight: 500;
    font-size: 14px;
}

.containData .card-details .card.action-green .card-header {
    background-color: #15C39A;
    color: #fff;
}



.containData .card-details .card.action-red .card-header {
    background-color: #1B2626;
    color: #fff;
}

.containData .card-details .card.action-green a,
.containData .card-details .card.action-green Link,
.containData .card-details .card.action-red a,
.containData .card-details .card.action-red link {
    text-decoration: none;
}


.containData .card-details .card .list-group-item {
    font-weight: 500;
    border-bottom: 1px solid #CEDDE8;

}

.containData .card-details .card .card-header {
    border-bottom: 1px solid #CEDDE8;
}

.containData .card-details .card .list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0;
}

.containData .card-details .card .list-group-item p {
    color: #8F9092;
    font-size: 10px;
    display: block;
    margin-bottom: 0;
    font-weight: 500;
    font-family: 'poppins';
}

.containData .card-details .card.action-green .card-header,
.containData .card-details .card.action-red .card-header {
    border-bottom: none
}

.containData .inspection-header .title {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.containData .inspection-header .btn img {
    width: 12px;
    height: 12px;
    margin: 0px 9px 0px 0px;
}

/****  TIRES CARD DETAILS  ****** */

.containData .tire-details .card-header img {
    margin-right: 10px;

}

.containData .tire-details .card-header {
    background: transparent !important;
    font-weight: 700 !important;
    color: #000 !important;
    text-transform: uppercase !important;
}

.containData .tire-details .card .list-group-item {
    font-weight: 500;
}

.containData .tire-details .card .list-group-item p {
    color: #8F9092;
    font-size: 10px;
    display: block;
    margin-bottom: 0;
    font-weight: 500;
}

.containData .tire-details .card .list-group .list-group-item {
    padding: 0.5rem;
}

.containData .tire-details .card .list-group-item span.fair {
    color: #2579DD;
}

/* ******* INSPECTION ***** */
.containData .inspection-header .title {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.containData .inspection-header .btn img {
    width: 12px;
    height: 12px;
    margin: 0px 9px 0px 0px;
}

/****  PICTURES CARD DETAILS  ****** */

.containData .picture-tab .card img {
    width: 100px;
    height: 100px;
    overflow: hidden;
}

.containData .picture-tab a.link {
    color: #000;
}

.containData .picture-tab a.link:hover {
    color: #00a780;
}

.containData .picture-tab .pick-area {
    min-height: 45px;
}

.containData .picture span.picture-pop-label {
    color: #2579DD;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    display: block;
    text-align: center;
}

.containData .picture span.picture-pop-label {
    background: #fff;
    border: 1px solid #2579DD;
    left: auto;
    right: 3px;
    bottom: 3px;
    position: absolute;
}

/* ******* BORDERLESS TABLE DATA ***** */
.containData .borderless-data .table tr td p {
    color: #8F9092;
    font-size: 11px;
    display: block;
    font-weight: 500;
    margin-bottom: 0.2rem;
}

.containData .borderless-data .table tr td {
    color: #000;
    font-weight: 500;
}

/* ******** TABS ***** */
.containData .responsive-tabs .nav-tabs .nav-link {
    font-weight: 500;
    color: #000;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #dee2e6;
    border-bottom: 0;
}

.containData .responsive-tabs .nav-tabs {
    padding-left: 15px;
}

.containData .responsive-tabs .nav-tabs .nav-item {
    margin: 0 0.5rem;
}

.containData .responsive-tabs .nav-tabs .nav-item.show .nav-link,
.containData .responsive-tabs .nav-tabs .nav-link.active {
    background: #f9ebeb;
}

.containData .responsive-tabs .tab-content {
    padding: 18px;
    border: 1px solid #ccc;
}

.containData .responsive-tabs .nav-tabs .nav-item .nav-link i {
    margin-right: 6px;
}


/* Content */
.containData .content-wrap {
    position: relative;
}

.containData .content-wrap section {
    display: none;
    margin: 0 auto;
    padding: 1em;
    font-size: 0.80em;
    font-weight: 400;
}

.containData .content-wrap section.content-current {
    display: block;
}

/* ********** INNER TABLE DATA ****** */

.containData .table-check-box th:first-child {
    width: 30px;
}


.containData .inner-table-data table th,
table th {
    color: #8F9092;
    font-size: 12px;
    font-weight: 500;
}

.containData .inner-table-data table th {
    text-transform: uppercase;
}

.containData .inner-table-data table td {
    font-weight: 600;
    font-size: 11px;
}

.containData .inner-table-data table td a {
    text-decoration: underline;
    color: #E21E1E;
}

.containData .inner-table-data table td a:hover {
    text-decoration: none;
    color: #000;
}

.containData .inner-table-data p.muted {
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    color: #8F9092;
    margin: 0;
    font-weight: 500;
}



/* ***** FUEL SECTION **** */

.containData .fuel-section .feature-icon img {
    background: #ebeef1;
    border-radius: 4px;
    width: 80px;
    height: 65px;
    margin-right: 15px;
}

.containData .fuel-section .level {
    font-weight: 400;
    margin-bottom: 5px;
}

.containData .fuel-section .km-range {
    font-weight: 600;
    color: #000;
    padding-left: 6%;
}

.containData .fuel-section .gradient {
    background-color: transparent;
    background-image: linear-gradient(to right, #ff0000, #ff4f00, #ff7500, #ff9300, #ffaf00);
}

/* ********** GALLERY CAROSUEL ***** */

.containData h2.title-head {
    font-size: 12px;
    font-weight: 700;
}

.containData h2.title-head-sub {
    font-size: 12px;
    font-weight: 600;
}

.containData .gallery-carousel .carousel-control-prev-icon,
.containData .gallery-carousel .carousel-control-next-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.containData .gallery-carousel .carousel-control-next,
.containData .carousel-control-prev {
    width: 9%;
}


.containData .gallery-carousel .card {
    margin: 0px 5px;
    border-color: #b7b7b7;
}

.containData .gallery-carousel .card,
.containData .gallery-carousel .card-img,
.containData .gallery-carousel .card-img img,
.containData .gallery-carousel .card-img-bottom {
    border-radius: 6px;
}

.single-line-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 24px;
    height: 34px;
}

/* ******* TRAILER ****** */
#image-map {
    background: url("../../images/trailer.png") no-repeat top center;
    position: relative;
    margin: 0 auto !important;
    width: 290px;
    height: auto;
    background-size: contain;
    min-height: 300px;
}

#image-map .hotspot {
    background-color: #f9ebeb;
    border: #e9c0c0 1px solid;
    border-radius: 100%;
    cursor: pointer;
    height: 0.8rem;
    width: 0.8rem;
    overflow: hidden;
    position: absolute;
}

#image-map .hotspot.blob-red {
    background: #ff5252;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
}

#image-map .hotspot .spot {
    display: block;
    opacity: 0;
}

#image-map #spot-1 {
    left: 7.5rem;
    top: 0.3rem;
}

#image-map #spot-2 {
    left: 9.8rem;
    top: 0.3rem;
}

#image-map #spot-3 {
    left: 7.55rem;
    top: 5.5rem;
}

#image-map #spot-4 {
    left: 10rem;
    top: 5.5rem;
}

#image-map #spot-5 {
    left: 10rem;
    top: 10rem;
}

#image-map #spot-6 {
    left: 10rem;
    top: 14rem;
}

#image-map #spot-7 {
    left: 7.55rem;
    top: 13.6rem;
}

#image-map #spot-8 {
    left: 7.5rem;
    top: 17.8rem;
}

#image-map #spot-9 {
    left: 9.95rem;
    top: 17.8rem;
}

#image-map #spot-10 {
    left: 7.55rem;
    top: 8.4rem;
}

/* ***** BTN COMPARE ***** */

.containData .compare-buttons,
.btn-compare {
    font-weight: 600;
}

.containData .btn-compare i {
    margin-right: 6px;
}

.containData .btn-compare.active {
    background: #FFEBEB !important;
    border-color: #ababab !important;
}

.containData .btn-compare .badge {
    letter-spacing: 0.06rem;
}


.containData .compare-buttons span.title-txt {
    display: block;
}

.containData .compare-buttons span.title-txt {
    display: inline-block;
}




@keyframes pulse-white {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}


@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

/* ********* MODAL POPUP CUSTOM ******** */
/* ******** GALLERY SCROLL ***************** */
.gallery-popup {}

.gallery-popup .gallery-description-wrapper {
    background: #fff;
    padding: 1rem;
    color: #000;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.gallery-popup .gallery-description-wrapper .content {
    padding: 0 1.5rem;
}

.gallery-popup .modal-body {
    padding: 0;
}

#galleria {
    height: 600px;
}

#galleria-loader {
    height: 1px !important
}

.galleria-theme-classic .galleria-frame img {
    width: 100% !important;
}

.galleria-theme-classic {
    position: relative;
    overflow: hidden;
    background: #fff;
    width: 100% !important;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.galleria-theme-classic img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none
}

.galleria-theme-classic .galleria-stage {
    position: absolute;
    top: 10px;
    bottom: 60px;
    left: 10px;
    right: 10px;
    overflow: hidden
}

.galleria-theme-classic .galleria-thumbnails-container {
    height: 50px;
    bottom: 0;
    position: absolute;
    left: 10px;
    right: 10px;
    z-index: 2
}

.galleria-theme-classic .galleria-carousel .galleria-thumbnails-list {
    margin-left: 30px;
    margin-right: 30px
}

.galleria-theme-classic .galleria-thumbnails .galleria-image {
    height: 40px;
    width: 60px;
    background: #000;
    margin: 0 5px 0 0;
    border: 1px solid #000;
    float: left;
    cursor: pointer
}

.galleria-theme-classic .galleria-counter {
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: right;
    color: #fff;
    font: normal 11px/1 arial, sans-serif;
    z-index: 1
}

.galleria-theme-classic .galleria-loader {
    background: #000;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    display: none;
    /* background: url(../images/classic-loader.gif) no-repeat 2px 2px */
}

.galleria-theme-classic .galleria-info {
    width: 50%;
    top: 15px;
    left: 15px;
    z-index: 2;
    position: absolute
}

.galleria-theme-classic .galleria-info-text {
    background-color: #000;
    padding: 12px;
    display: none;
    zoom: 1
}

.galleria-theme-classic .galleria-info-title {
    font: bold 12px/1.1 arial, sans-serif;
    margin: 0;
    color: #fff;
    margin-bottom: 7px
}

.galleria-theme-classic .galleria-info-description {
    font: italic 12px/1.4 georgia, serif;
    margin: 0;
    color: #bbb
}

.galleria-theme-classic .galleria-info-close {
    width: 9px;
    height: 9px;
    position: absolute;
    top: 5px;
    right: 5px;
    background-position: -753px -11px;
    opacity: .5;
    cursor: pointer;
    display: none
}

.galleria-theme-classic .notouch .galleria-info-close:hover {
    opacity: 1
}

.galleria-theme-classic .touch .galleria-info-close:active {
    opacity: 1
}

.galleria-theme-classic .galleria-info-link {
    background-position: -669px -5px;
    opacity: .7;
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-color: #000
}

.galleria-theme-classic.notouch .galleria-info-link:hover {
    opacity: 1
}

.galleria-theme-classic.touch .galleria-info-link:active {
    opacity: 1
}

.galleria-theme-classic .galleria-image-nav {
    position: absolute;
    top: 50%;
    margin-top: -62px;
    width: 100%;
    height: 62px;
    left: 0
}

.galleria-theme-classic .galleria-image-nav-left,
.galleria-theme-classic .galleria-image-nav-right {
    opacity: .3;
    cursor: pointer;
    width: 62px;
    height: 124px;
    position: absolute;
    left: 10px;
    z-index: 2;
    background-position: 0 46px
}

.galleria-theme-classic .galleria-image-nav-right {
    left: auto;
    right: 10px;
    background-position: -254px 46px;
    z-index: 2
}

.galleria-theme-classic.notouch .galleria-image-nav-left:hover,
.galleria-theme-classic.notouch .galleria-image-nav-right:hover {
    opacity: 1
}

.galleria-theme-classic.touch .galleria-image-nav-left:active,
.galleria-theme-classic.touch .galleria-image-nav-right:active {
    opacity: 1
}

.galleria-theme-classic .galleria-thumb-nav-left,
.galleria-theme-classic .galleria-thumb-nav-right {
    cursor: pointer;
    display: none;
    background-position: -495px 5px;
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    width: 23px;
    z-index: 3;
    opacity: .8
}

.galleria-theme-classic .galleria-thumb-nav-right {
    background-position: -578px 5px;
    border-right: none;
    right: 0;
    left: auto
}

.galleria-theme-classic .galleria-thumbnails-container .disabled {
    opacity: .2;
    cursor: default
}

.galleria-theme-classic.notouch .galleria-thumb-nav-left:hover,
.galleria-theme-classic.notouch .galleria-thumb-nav-right:hover {
    opacity: 1;

}

.galleria-theme-classic.touch .galleria-thumb-nav-left:active,
.galleria-theme-classic.touch .galleria-thumb-nav-right:active {
    opacity: 1;

}

.galleria-theme-classic.notouch .galleria-thumbnails-container .disabled:hover {
    opacity: .2;
    background-color: transparent
}

.galleria-theme-classic .galleria-carousel .galleria-thumb-nav-left,
.galleria-theme-classic .galleria-carousel .galleria-thumb-nav-right {
    display: block
}

.galleria-theme-classic .galleria-image-nav-left,
.galleria-theme-classic .galleria-image-nav-right,
.galleria-theme-classic .galleria-info-close,
.galleria-theme-classic .galleria-info-link,
.galleria-theme-classic .galleria-thumb-nav-left,
.galleria-theme-classic .galleria-thumb-nav-right {
    /* background-image: url(../images/classic-map.png); */
    background-repeat: no-repeat
}

.galleria-theme-classic.galleria-container.videoplay .galleria-counter,
.galleria-theme-classic.galleria-container.videoplay .galleria-info {
    display: none !important
}


.galleria-theme-classic .galleria-info-link {
    display: none !important;
}

/* ***** GALLERY FORM TEXTAREA ***** */
.gallery-form .form-floating>.form-control:not(:placeholder-shown) {
    padding-top: .625rem;
}


/* ***** SCROLL BAR ****************** */
.scrollbar {
    padding: 0px 12px 0px 0px;
    width: auto;
    height: 400px;
    overflow-y: scroll;
    mask-image: linear-gradient(to top, transparent, black),
        linear-gradient(to left, transparent 17px, black 17px);
    mask-size: 100% 20000px;
    mask-position: left bottom;
    -webkit-mask-image: linear-gradient(to top, transparent, black),
        linear-gradient(to left, transparent 17px, black 17px);
    -webkit-mask-size: 100% 20000px;
    -webkit-mask-position: left bottom;
    transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}

.scrollbar:hover {
    -webkit-mask-position: left top;
}


.scrollbar::-webkit-scrollbar {
    width: 5px;
}

.scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px red;
}



/* ***** BTN COMPARE ***** */

.compare-buttons,
.btn-compare {
    font-weight: 600;
}

.btn-compare i {
    margin-right: 6px;
}

.btn-compare.active {
    background: #FFEBEB !important;
    border-color: #ababab !important;
}

.btn-compare .badge {
    letter-spacing: 0.06rem;
}


.compare-buttons span.title-txt {
    display: block;
}

.compare-buttons span.title-txt {
    display: inline-block;
}

/* Multi Release Inspection Document */

.container .new-release-body {
    min-height: 440px;
    border: 0.5px solid #e6e6e6;
    border-radius: 10px;
}

.new-release-body .new-release-tabs {
    float: left !important;
    width: "30%";
}

.new-release-body .new-release-tabs ul {
    display: block;
}

.new-release-body .new-release-tabs ul li {
    padding: 16px 20px 0px 20px;
}

.new-release-body .new-release-tabs ul li button {
    display: block;
    align-content: center;
    color: white;
    height: "30px";
    font-weight: 500;
    padding: 6px 12px !important;
    width: 100%;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #15C39A;
    background: #15C39A;
}

.new-release-body .new-release-tabs ul li button:hover {
    background-color: #157347;
}

.new-release-body .new-release-tabs ul li button.active {
    background-color: #ffbe4e;
}

.radio-size {
    height: 16px;
    width: 16px;
}

.textarea {
    margin-left: 12px;
    width: 80%;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.release-details {
    border: 1px solid #ccc;
    padding: 8px;
}

.release-details p {
    font-weight: 500;
}

.picture-details .card-body{
    height: auto !important;
}

.upload-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
}

.upload-image .title{
    font-weight: 500;
    font-size: 15px;
}

.attach{
    margin-top: 30px;
    text-decoration: none;
    display: block;
}
.attach span {
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 500;
    margin-top: 16px;
    background: #E21E1E33;
    border: #db7373 1px solid;
    color: #000;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}


div.upload {
    position: relative;
    left: 10px;
    top: 15px;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div.upload label {
    cursor: pointer;
  }
  div.upload label input {
    display: none;
  }
  div.upload label span {
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px dashed;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s;
  }
  div.upload label span i {
    position: absolute;
    font-size: 12px;
  }
  div.upload label span:hover {
    transform: scale(0.8);
  }

@media (min-width: 1400px) {
    .admin-wrapper .container {
        max-width: 1920px;
    }

    .m-b-0 {
        margin-bottom: 0 !important;
    }


}


@media (max-width: 767px) {
    .carousel-inner .carousel-item>div {
        display: none;
    }

    .carousel-inner .carousel-item>div:first-child {
        display: block;
    }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex;
}

/* medium and up screens */
@media (min-width: 768px) {

    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
        transform: translateX(25%);
    }

    .carousel-inner .carousel-item-start.active,
    .carousel-inner .carousel-item-prev {
        transform: translateX(-25%);
    }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
    transform: translateX(0);
}

@media (max-width: 600px) {
    .m-m-b-15 {
        margin-bottom: 15px;
    }

    .m-m-b-10 {
        margin-bottom: 10px;
    }
}

@media (max-width: 400px) {
    .compare-buttons span.title-txt {
        display: block;
    }

}

.tires .card{
    margin-bottom: 1.5rem !important;
}

.tires .card .card-body{
    height: auto !important;
    padding: 1rem !important;
}

.tires .card .card-header{
background-color:#D2E3E2;
}

.tires .card .card-title{
    font-weight: 600;
    margin-bottom: 0;
    font-size: 13px;
}

.tires .card .card-body{
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;

}

.f-12 {
    font-size: 12px;
}

.title-head{
    font-size: 1rem;
}

.attachment .react-multi-carousel-list{
    justify-content: space-evenly;
}

#attachments-viewer .modal-content{
    border: 1px solid #656565;
    box-shadow: 5px 10px #888888;
}