.button-1{
width: 150px;
height: 36px;
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 0.5px solid #00000066;
opacity: 1;
}

.ag-theme-customeTheme{
  --ag-border-color: #F0F7F6 !important;
  opacity: 1;
}

.ag-theme-customeTheme .header-white{
  background-color: #DEE6E5;
}

.ag-theme-customeTheme .header-white-bg{
  background-color: #FFFFFF;
  padding-left: 10px;
}

.ag-theme-customeTheme .ag-header-cell-text{
  letter-spacing: 0 !important;
  font-size: 12px;
  color: black;
  font-weight: 500;
  font-family: 'poppins';
}


.inspection-tabs .nav-tabs .nav-item.active {
	font-weight: 700;
	color: red !important; 
}


.button-2{
  margin-top: 20px;
  margin-left: 500px;
  width: 100px;
  height: 35px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #1B2626;
  opacity: 1;
}

.img1{
  height:20px;
  width:20px;
}


.searchIcon .searchBar {
    padding-left: 2.375rem;
}

.searchIcon .searchIconDisplay {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.searchBar{
width: 250px;
height: 35px;
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 0.5px solid #00000066;
opacity: 1;
}


.clsText {
	font-size: 12px;
	font-weight: 400;
}

.img{
width: 21px;
height: 21px;
margin-right: 10px;
}

::placeholder {
  font-size: 12px !important;
}

.ag1{
  background-color: white;
}
  
  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    height: 38px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid black;
    
  }
  
  .pagination a.active {
    background-color: #FFFFFF;
    color: #E21E1E;
    border: 1px solid #E21E1E;
    opacity: 1;
  }

  .table-data .rdt_TableHead{
    font-size: 10px;
    color: #8F9092;
    opacity: 1;
    font-family: montserrat, serif;
  }
  
  .table-data{
    font-family: montserrat, serif;
    margin-top: 20px;
    }

    .table-data .rdt_TableCell{
        font-family: montserrat, serif;
        font-weight: 600;
        }

    .Card1{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 25px #0000000A;
        border-radius: 4px;
        opacity: 1;
        margin-left: 20px;
    }

.input10{
    height: 3rem;
    width:10rem;
}

.card1{
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 10px 54px #0000000D;
opacity: 1;
}

.card2{
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 13px #0000000A;
border: 0.5px dashed #CDDEE8;
opacity: 1;
}

