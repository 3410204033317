/*@import url('http://fonts.cdnfonts.com/css/gotham');*/
/*@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700');*/

/*@font-face {
	font-family: 'Gotham';
	font-style: normal;
	font-weight: 400;
	src: local('Gotham'), url('https://fonts.cdnfonts.com/s/14898/GothamBook.woff') format('woff');
}
@font-face {
	font-family: 'Gotham';
	font-style: italic;
	font-weight: 400;
	src: local('Gotham'), url('https://fonts.cdnfonts.com/s/14898/GothamBookItalic.woff') format('woff');
}
@font-face {
	font-family: 'Gotham';
	font-style: normal;
	font-weight: 300;
	src: local('Gotham'), url('https://fonts.cdnfonts.com/s/14898/GothamLight.woff') format('woff');
}
@font-face {
	font-family: 'Gotham';
	font-style: italic;
	font-weight: 300;
	src: local('Gotham'), url('https://fonts.cdnfonts.com/s/14898/GothamLightItalic.woff') format('woff');
}
@font-face {
	font-family: 'Gotham';
	font-style: normal;
	font-weight: 500;
	src: local('Gotham'), url('https://fonts.cdnfonts.com/s/14898/GothamMedium.woff') format('woff');
}
@font-face {
	font-family: 'Gotham';
	font-style: normal;
	font-weight: 500;
	src: local('Gotham'), url('https://fonts.cdnfonts.com/s/14898/GothamMedium_1.woff') format('woff');
}
@font-face {
	font-family: 'Gotham';
	font-style: italic;
	font-weight: 500;
	src: local('Gotham'), url('https://fonts.cdnfonts.com/s/14898/GothamMediumItalic.woff') format('woff');
}
@font-face {
	font-family: 'Gotham';
	font-style: normal;
	font-weight: 700;
	src: local('Gotham'), url('https://fonts.cdnfonts.com/s/14898/GothamBold.woff') format('woff');
}
@font-face {
	font-family: 'Gotham';
	font-style: normal;
	font-weight: 700;
	src: local('Gotham'), url('https://fonts.cdnfonts.com/s/14898/Gotham-Bold.woff') format('woff');
}
@font-face {
	font-family: 'Gotham';
	font-style: italic;
	font-weight: 700;
	src: local('Gotham'), url('https://fonts.cdnfonts.com/s/14898/GothamBoldItalic.woff') format('woff');
}
@font-face {
	font-family: 'Gotham Black';
	font-style: normal;
	font-weight: 500;
	src: local('Gotham Black'), url('https://fonts.cdnfonts.com/s/14898/Gotham-Black.woff') format('woff');
}
@font-face {
	font-family: 'Gotham Light';
	font-style: normal;
	font-weight: 500;
	src: local('Gotham Light'), url('https://fonts.cdnfonts.com/s/14898/Gotham-Light.woff') format('woff');
}
@font-face {
	font-family: 'Gotham Thin';
	font-style: normal;
	font-weight: 500;
	src: local('Gotham Thin'), url('https://fonts.cdnfonts.com/s/14898/Gotham-Thin.woff') format('woff');
}
@font-face {
	font-family: 'Gotham XLight';
	font-style: normal;
	font-weight: 500;
	src: local('Gotham XLight'), url('https://fonts.cdnfonts.com/s/14898/Gotham-XLight.woff') format('woff');
}
@font-face {
	font-family: 'Gotham Book';
	font-style: italic;
	font-weight: 500;
	src: local('Gotham Book'), url('https://fonts.cdnfonts.com/s/14898/Gotham-BookItalic.woff') format('woff');
}
@font-face {
	font-family: 'Gotham Thin';
	font-style: italic;
	font-weight: 500;
	src: local('Gotham Thin'), url('https://fonts.cdnfonts.com/s/14898/Gotham-ThinItalic.woff') format('woff');
}
@font-face {
	font-family: 'Gotham Ultra';
	font-style: italic;
	font-weight: 500;
	src: local('Gotham Ultra'), url('https://fonts.cdnfonts.com/s/14898/Gotham-UltraItalic.woff') format('woff');
}
@font-face {
	font-family: 'Gotham XLight';
	font-style: italic;
	font-weight: 500;
	src: local('Gotham XLight'), url('https://fonts.cdnfonts.com/s/14898/Gotham-XLightItalic.woff') format('woff');
}*/

/* cyrillic-ext */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
	format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
	U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
	format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
	format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
	U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
	format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
	U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
	format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
	U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
	U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
	format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
	U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
	format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
	format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
	U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
	format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
	U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
	format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
	U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
	U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
	format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
	U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
	format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
	format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
	U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
	format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
	U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
	format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
	U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
	U+FEFF, U+FFFD;
}

body {
	/*font-family: 'Gotham';*/
	font-family: Montserrat, sans-serif;
	font-size: 13px;
	background: #f9f9f9;
	color: #000;
}

/*Colors*/
.grey-text {
	color: #777779 !important;
}

.darkgrey-text {
	color: #373935;
}
.black-text {
	color: #393c47 !important;
}

.signup-text {
	color: #373935;
	font-weight: 700;
}

/*custom bootstrap classes*/
.w-22 {
	width: 22% !important;
}

.fs-7 {
	font-size: 0.875rem !important;
}

/*login styles*/
.login-banner-bg {
	background-image: radial-gradient(#ebf7f5, #63c1ab);
	height: 100vh;
}
.login-banner-bg1 {
	background-image: radial-gradient(#9b9b9b, #999999);
	height: 100vh;
}

.login-banner2 {
	background-image: url('./images/2.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.login-banner3 {
	background-image: url('./images/banner1.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.login-banner4 {
	background-image: url('./images/074.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.logo {
	/*background-image: url('./images/logo.png');*/
	background-repeat: no-repeat;
	height: 17px;
}

.login-btn {
	color: #373935;
	background-color: #efc300;
	border-color: #efc300;
}

.login-btn:hover,
.login-btn:active,
.login-btn:focus {
	color: #373935;
	background-color: #efc300;
	border-color: #efc300;
}

.login-input {
	color: #393c47;
	background: #ffffff;
	border: 1px solid #eaeaea;
	border-radius: 4px;
	height: 56px;
}
.form-control:focus {
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
.form-control {
	display: block;
	width: 100%;
	height: 30px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	-webkit-transition: border-color ease-in-out 0.15s,
	box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	-webkit-transition: border-color ease-in-out 0.15s,
	-webkit-box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s,
	-webkit-box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
	-webkit-box-shadow ease-in-out 0.15s;
}

.login-vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.required:after {
	content: ' *';
	color: red;
}

.form-select {
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	font-size: 13px;
}

@media screen and (max-width: 767px) {
	.login-vertical-center {
		margin: 0;
		position: relative;
		top: 0%;
		-ms-transform: translateY(0%);
		transform: translateY(0%);
	}
}

.text-banner {
	margin-left: 20vw;
	margin-top: 85vh;
}

.banner-main {
	color: #ffffff;
	font-style: italic;
	font-weight: 600;
}

.banner-sub {
	color: #ffffff;
	font-size: 25px;
	font-weight: 100;
}
.loginError {
	color: red;
	font-weight: bold;
}

/*.account .signup-text{
	color: #246B5A;
}*/

/* Menu & Sidebar */
#sidebarCollapse {
	width: 40px;
	height: 40px;
	/*background: #f5f5f5;*/
}

#sidebarCollapse span {
	width: 80%;
	height: 2px;
	margin: 0 auto;
	display: block;
	background: #555;
	transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
}

#sidebarCollapse span:first-of-type {
	/* rotate first one */
	transform: rotate(45deg) translate(2px, 2px);
}
#sidebarCollapse span:nth-of-type(2) {
	/* second one is not visible */
	opacity: 0;
}
#sidebarCollapse span:last-of-type {
	/* rotate third one */
	transform: rotate(-45deg) translate(1px, -1px);
}

#sidebarCollapse.active span {
	/* no rotation */
	transform: none;
	/* all bars are visible */
	opacity: 1;
	margin: 5px auto;
}

.bg-breadcrumb {
	background-color: #fbedb3;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
	border-radius: 0px;
	height: 30px;
}

.breadcrumb-item.active {
	color: #373935 !important;
	opacity: 0.5 !important;
}

#menu > li > a {
	color: #000 !important;
	opacity: 0.7;
}

/*#menu > li > a::before{
	content: "test";
	background-image: url(../images/icons/arrow.svg);
	width: 15px;
	height: 15px;
}*/

#menu > li > ul > li a {
	color: #000 !important;
	font-size: 13px;
	font-weight: 600;
}

.menu-hr {
	border: 1px solid #f1f2f3 !important;
	margin-top: 0.725rem;
	margin-bottom: 0.725rem;
	width: 93% !important;
	margin-left: 0px !important;
}

.menu-icon {
	margin-right: 12px;
}

p > #headerSearch {
	color: #777779;
	font-weight: 500;
}

p > #headerLang {
	color: #777779;
	font-weight: 500;
}

#headerSearch > span:hover {
	border-bottom: 2px Solid #63c1ab;
}

#headerLang > span:hover {
	border-bottom: 2px Solid #63c1ab;
}

.top-nav {
	height: 64px;
}

.navbar-nav .dropdown-menu {
	position: absolute;
	left: -175px;
}

.offcanvas-start {
	top: 45px !important;
}

/* Organization Styles */

.clsLastAccessed {
	padding-top: 5px !important;
	text-align: right;
}
.clsSearchCntr {
	padding-right: 10px !important;
}
.clsLastAccessedMenu {
	left: 53px !important;
	top: -24px !important;
}
.clsNoRightMargin {
	margin-right: 0px !important;
}
.clsStandardText {
	font-size: 14px;
	font-weight: 400;
}
.clsBoldText, .ag-header-cell-text {
	font-size: 13px;
	font-weight: 500;
}
.clsBolderText {
	font-size: 14px;
	font-weight: 700;
}
.clsAnchor {
	color: #085be7;
}
.clsAnchor:hover {
	color: #085be7;
	font-weight: bold;
}
.top-nav {
	height: 64px;
}
#sidebarCollapse {
	width: 32px;
	height: 32px;
}
.offcanvas-start > .offcanvas-body > .nav-pills {
	margin-left: 32px;
}
.fs-7 {
	font-size: 0.875rem !important;
}

.offcanvas-start > .offcanvas-body > .nav-pills {
	margin-left: 10px;
}

.nav-search {
	height: 35px;
}
.nav-separator {
	color: #373935 !important;
	opacity: 0.2;
}

/* Organization Styles */

.clsLastAccessed {
	padding-top: 5px !important;
	text-align: right;
}
.clsSearchCntr {
	padding-right: 10px !important;
}
.clsLastAccessedMenu {
	left: 53px !important;
	top: -24px !important;
}
.clsNoRightMargin {
	margin-right: 0px !important;
}
.clsStandardText {
	font-size: 14px;
	font-weight: 400;
}
.clsBoldText, .ag-header-cell-text {
	font-size: 13px;
	font-weight: 500;
	color: #373935;
}
.clsBolderText {
	font-size: 14px;
	font-weight: 700;
	color: #373935;
}
.clsAnchor {
	color: #085be7;
}
.clsAnchor:hover {
	color: #085be7;
	font-weight: bold;
}

/* Organization General Info Styles */
.clsTabButton {
	font-weight: 600;
	font-size: 14px;
	line-height: 13px;
	color: #777779;
	width: 100%;
	border-radius: 0px;
	background: rgba(119, 119, 121, 0.05);
	border: 1px solid rgba(119, 119, 121, 0.2);
	height: 48px;
}
.clsTabButton img {
	vertical-align: middle;
	margin-top: -2px;
	margin-right: 8px;
}
.clsTabSelected,
.clsTabSelected:hover,
.clsTabSelected:focus {
	border: 0px;
	border-bottom: 2px solid #3cb296;
	color: #373935;
	font-weight: 600;
	background: #ebf7f5;
}
.clsNoPaddingLeft {
	padding-left: 0px;
}
.clsNoPaddingRight {
	padding-right: 0px;
}
.clsOrgGenLogo {
	text-align: center;
}
.clsOrgGenFormButtonRow {
	margin-top: 130px;
}
.clsFormButtonNormal {
	color: #373935;
	border: 1px solid #373935;
	border-radius: 4px;
	width: 160px !important;
	font-weight: 600;
	font-size: 14px;
	line-height: 13px;
	height: 40px;
}
.clsFormButtonDefault,
.clsFormButtonDefault:focus,
.clsFormButtonDefault:hover {
	color: #373935;
	background-color: #efc300;
	border-color: #efc300;
	width: 160px !important;
	font-weight: 600;
	font-size: 14px;
	line-height: 13px;
	height: 40px;
}
.clsOrgGenAddressRow {
	margin-top: 30px;
	margin-bottom: 30px;
}
.clsAlignRight {
	text-align: right;
}
.clsOrgRolesSearchRow {
	margin-top: 8px;
	margin-bottom: 8px;
}
.clsDispInlineBlock {
	display: inline-block;
}
.clsOrgRolesSearchCntr {
	display: inline-block;
}
.clsOrgRolesSearchCntr input {
	height: 40px;
}
.clsOrgRolesAddBtn {
	margin-top: -5px;
	margin-left: 10px;
}
.clsFormInputControl-select {
	border-bottom: 1px solid rgba(227, 27, 26, 0.5);
	border-radius: 4px;
}
.clsFormInputControl {
	padding: 8px 16px;
	height: 30px;
	border-bottom: 1px solid rgba(227, 27, 26, 0.5);
	border-radius: 4px;
}
.clsFormStandardTopMargin {
	margin-top: 20px;
}
.clsFormSectionSep {
	border-bottom: 1px solid rgba(119, 119, 121, 0.2);
	margin-bottom: 24px;
}
.clsFormButtonNormal {
	color: #373935;
	border: 1px solid #373935;
	border-radius: 4px;
	width: 160px !important;
	font-weight: 600;
	font-size: 14px;
	line-height: 13px;
	height: 40px;
}
.clsFormButtonDefault {
	color: #373935;
	background-color: #efc300;
	border-color: #efc300;
	width: 160px !important;
	font-weight: 600;
	font-size: 14px;
	line-height: 13px;
	height: 40px;
}
.clsAlignRight {
	text-align: right;
}
.cancel-btn {
	border: 1px solid #373935;
	border-radius: 4px;
	width: 160px;
}
.driver-btn,
.driver-btn:focus,
.driver-btn:hover {
	background: #efc300;
	border-radius: 4px;
	width: 160px;
}
.clsOrgRolePermissionsList li {
	margin: 0px !important;
	padding-top: 12px !important;
	padding-bottom: 12px !important;
	font-size: 16px;
	color: #373935;
}
.clsOrgRolePermissionsList li .form-check-input:checked {
	background-color: #3cb296;
	border-color: #3cb296;
}
.clsOrgRolePermissionsList li input{
	position: relative;
	right: 40px;
}
.companyLogo {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	border: 1px solid #e9e4e4;
	cursor: pointer;
}
.logoNotAvail {
	padding: 5px;
	width: 100px;
	height: 100px;
	border-radius: 50px;
	border: 1px solid #999999;
}
.flex {
	display: flex;
}
.labelpadding {
	padding-left: 10px;
}
.lang-ddl > li > .active {
	background: rgba(235, 247, 245);
	border-radius: 4px;
	color: #373935;
	font-weight: 700;
	width: 180px !important;
}
.clsOrgRolePermissionsList {
	list-style: none;
	padding-left: 0px;
}
.accountApprBg {
	background: rgba(60, 178, 150, 0.1);
	height: 40px;
	border-radius: 4px;
	margin-top: 5px;
}
.accountPendingBg {
	background: #F7FBCD !important;
	height: 40px;
	border-radius: 4px;
	margin-top: 5px;
}
.accountApprBtnRed,
.accountApprBtnRed:hover,
.accountApprBtnRed:active {
	background: #e31b1a;
	border-radius: 2px !important;
	color: white;
	font-size: 12px !important;
	height: 28px;
	/* padding: 0.3rem 0.75rem !important; */
	font-weight: 600;
	/* margin-left: 55px;
	margin-right: 8px; */
	/* width: 100px; */
}
.accountApprBtnRed:hover {
	color: white;
}
.accountApprBtnAprove,
.accountApprBtnAprove:hover,
.accountApprBtnAprove:active {
	background: #198754;
	border-radius: 2px !important;
	color: white;
	font-size: 12px !important;
	height: 28px;
	/* padding: 0.3rem 0.75rem !important; */
	font-weight: 600;
	/* margin-left: 55px;
	margin-right: 8px; */
	width: 100px;
	margin: 0px 15px;
}
.accountApprBtnAprove:hover {
	color: white;
}
.clsCustProfileBtnSection {
	margin-top: 10px;
	border-top: 1px solid rgba(119, 119, 121, 0.2);
	/*box-shadow: 0px -2px 4px rgb(0 0 0 / 10%);*/
}
.headerSearch,
.headerLang {
	color: #777779 !important;
	font-weight: 600;
}
.headerSearch > span:hover {
	color: #373935 !important;
	border-bottom: 2px Solid #63c1ab;
}
.breadcrumb-lastarrow {
	width: 12px;
}
.form-control-font {
	font-size: 14px !important;
}
.login-btn-font {
	font-weight: 600;
}
.border-bottom-class {
	border-bottom: 1px solid rgba(227, 27, 26, 0.5);
}
.mandatory > .css-e0msvm-control {
	border-bottom: 1px solid rgba(227, 27, 26, 0.5);
	/*height: 39px;*/
	/*width: 90% !important;*/
	/*border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e);
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);*/
}
.select-box-invalid > .css-e0msvm-control {
	border-color: #dc3545;
	/*padding-right: calc(0.5em + 0.75rem);
      background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e);*/
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	/*height: 39px;*/
	/*width: 90% !important;*/
}
.select-box-valid > .css-e0msvm-control {
	border-color: #198754;
	/*padding-right: calc(0.5em + 0.75rem);
      background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e);*/
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	/*height: 39px;*/
	/*width: 90% !important;*/
}
/* .css-1pahdxg-control,.css-1s2u09g-control{
	width: 90% !important;
} */
/* .css-2613qy-menu{
	width: 90% !important;
} */
.fw-500 {
	font-weight: 500 !important;
}
.fw-600 {
	font-weight: 600 !important;
}
.css-16ihq8s {
	display: block;
	margin-bottom: 6px;
	font-size: 12px;
	font-weight: 600;
	color: rgb(155, 155, 155);
}
.css-2rsrq9 {
	width: 100%;
	height: 30px;
	padding: 8px;
	border: 1px solid rgb(213, 213, 213);
	border-radius: 4px;
	background: rgb(255, 255, 255);
	box-shadow: none;
	font-family: inherit;
	font-size: 14px;
	border-bottom: 1px solid rgba(227, 27, 26, 0.5);
}
.orgInfo {
	font-size: 15px;
	font-weight: 600;
}
.dropdown-item:hover,
.dropdown-item:focus {
	background-color: #ebf7f5;
}
input[type='checkbox']:enabled:checked {
	/* background-color: #3cb296 !important; */
	color: white;
}

.container1 {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 12px;
	cursor: pointer;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.container1 input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: 2px black solid;
}

/* On mouse-over, add a grey background color */
.container1:hover input ~ .checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container1 input:checked ~ .checkmark {
	background-color: green;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container1 input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container1 .checkmark:after {
	left: 8px;
	bottom: 5px;
	width: 6px;
	height: 12px;
	border: solid white;
	border-width: 0 4px 4px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.email-margin-bottom {
	margin-top: 10% !important;
}
.collapse-button {
	padding-top: 7px !important;
}
.list-item-top {
	padding-top: 10px;
}
.user-profile > li > a {
	padding: 0.25rem 0.5rem !important;
}
.main-menu {
	height: 93%;
	border-bottom: 1px solid rgb(0, 0, 0, 0.175);
	border-left: 1px solid rgb(0, 0, 0, 0.175);
	left: 1px;
}
.cellRender {
	font-size: 14px;
}
.cellRenderHeader {
	padding-top: 0px;
	color: blue;
}

.interchangeRender{
	cursor: pointer;
}

.inspectionRenderHeader{
	padding-top: 0px;
	text-decoration: none;
	cursor: pointer;
	font-size:13px;
	color: blue;
}
.canvasBg {
	background: #f0f0f0;
}
.lastAccess {
	padding-top: 5px !important;
	font-size: 12px !important;
}
/* .Toastify__toast-container--top-right{
	right: 40%;
	top:90%
} */
.in-active-status,
.in-active-status:hover,
.in-active-status:focus {
	width: 100px;
	height: 26px;
	padding-top: 1px;
	/* border-radius: 50px; */
	background: #e31b1a;
	border: 1px solid #e31b1a;
	font-size: 13px;
	border-radius: 20px !important;
	padding-top: 2px !important;
	font-size: 11px !important;
}
.active-status,
.active-status:hover,
.active-status:focus {
	width: 100px;
	height: 26px;
	padding-top: 2px !important;
	border-radius: 20px !important;
	background: #3cb196;
	border: 1px solid #3cb196;
	font-size: 11px !important;
}
.icon-rotate-90 {
	transform: rotate(90deg);
}
.icon-rotate-180 {
	transform: rotate(180deg);
}
.card-header {
	font-size: 14px;
	font-weight: 600;
	background: #fff;
}
.card-body {
	font-size: 12px;
	padding: 5px;
	height: 75px;
}
.service-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.renter-service {
	display: flex;
	align-items: center;
	justify-content: center;
}
.service-box-height {
	height: 40px;
}
.ptop-5 {
	padding-top: 5px;
}
.service-input {
	border: 1px solid #beb8b8;
	width: 25%;
	font-weight: bold;
}
.browse-button {
	border: 1px;
	border-style: dashed;
	padding: 5px;
}
.browse {
	border: 1px solid #0dcaf0;
}
.flex-center {
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	/*text-align: center;*/
}
.remove-btn {
	color: red;
	font-size: 20px;
}
.right-logo {
	width: 50px !important;
	height: 20px !important;
	padding-top: 5px;
	margin-top: 5px;
	margin-right: 5px;
}
textarea[name='conditions'],
textarea[name='equipments'] {
	resize: none;
	border: 0px;
}
input[name='daily'],
textarea[name='conditions']:focus,
textarea[name='equipments']:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
input[name='daily'],
input[name='daily']:focus {
	border: 0px;
	width: 80px;
	background: none;
	box-shadow: none;
	border: 1px solid #000;
	height: 30px;
	background: #FFF;
}
input[name='daily']:disabled {
	border: 0px;
	background: none;
	background: none !important;
}
.upload-doc {
	cursor: pointer;
}
ul.no-bullets {
	list-style-type: none;
	font-size: 16px;
}
.language-item {
	display: flex;
	justify-content: space-between;
	padding-left: 5px;
	padding-right: 5px;
}
.language-item:hover {
	background: #ebf7f5;
}
.language-item-left {
	padding-left: 10px;
	font-size: 16px;
	text-decoration: none;
}
.language-sel-item {
	background: #ebf7f5;
	padding-left: 5px;
	padding-right: 5px;
}
.equip-boxes {
	height: 102px;
	border: 1px solid #777779;
	border-radius: 4px;
	width: 106px;
	margin-right: 16px;
}
.clsFormSectionHeading {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #393c47;
}
.upload-thumbnail {
	width: 100px;
	height: 100px;
	margin-top: -20px;
}
.mobile-thumbnail{
	width: 100px;
	height: 80px;
	margin-top: -28px;
}
.upload-thumbnail-remove{
	color: red;
	cursor: pointer;
	background:white
}
.flex-spacebetween{
	display: flex;
	justify-content: space-between;
}
.upload-border {
	/* border: 1px dashed rgba(119, 119, 121, 0.4); */
	/*border: 2px dashed #ddd;*/
	border-radius: 4px;
	height: 204px;
}

.deaction-box{
	right:5px !important;
}

.uploader {
	display: block;
	clear: both;
	/* width: 20%; */
}
.uploader label {
	float: left;
	clear: both;
	width: 100%;
	padding: 2rem 1.5rem;
	text-align: center;
	background: #fff;
	/* border-right: 1px dashed rgba(119, 119, 121, 0.4); */
	/*border-right: 4px dashed #ddd;*/
	transition: all 0.2s ease;
	user-select: none;
}
.uploader div {
	margin: 0 0 1rem 0;
	color: #000;
	border-radius: 4px;
	font-size: 10px !important;
	/*height: 204px;*/
}
.uploader #notimage.hidden {
	display: none;
}
.uploader #notimage {
	display: block;
	float: left;
	clear: both;
	width: 100%;
}
.equip-pricingBox {
	border: 1px solid rgba(119, 119, 121, 0.2);
	box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
	border-radius: 4px;
	height: 192px;
	text-align: center;
	width: 318px;
}
.equip-pricingInput {
	/*height: 44px;*/
	border: 1px solid rgba(119, 119, 121, 0.4);
	border-radius: 4px;
	padding: 0px 12px;
	width: 180px;
	display: inline-block;
	/* border-bottom: 1px solid rgba(227, 27, 26, 0.5); */
}
.equip-type-image {
	width: 100%;
	height: 100%;
	cursor: pointer;
}
.upload-file-box {
	height: 100px;
	border: 1px solid #e5dbdb;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}
.upload-image-item {
	display: flex;
	text-align: right;
	flex-direction: column;
	padding: 8px;
	/*border: 1px solid #ddd5d5;
	padding: 10px;*/
}

.registration-box {
	border: 1px solid #ccc;
	background: #f7f6ed;
	border-left: 4px solid #ffc107;
	display: flex;
	align-items: center;
	box-shadow: 0px 0px 10px 1px #ddd;
}
.equip-view-box {
	width: 120px;
	height: 120px;
	border: 1px solid #cccccc;
	border-radius: 50%;
	background: #f3ebeb;
}

.equip-view-header {
	border: 1px solid #efebeb;
	padding: 10px;
	background: #fff;
}
.display_inline {
	display: inline;
}
.equip-view-button {
	border: 1px solid #333;
	width: 225px;
}
.equi-view-select-btn {
	border: 0px;
	border-bottom: 2px solid #ffc107;
	color: #373935;
	font-weight: 600;
}

.specific-equip {
	cursor: pointer;
	height: 320px;
	display: inline-block !important;
	box-shadow: 0px 0px 15px 0px #ddd;
	border-radius: 20px;
}
.generic-equip {
	cursor: pointer;
	/* border:1px solid #999; */
	height: 320px;
	display: inline-block !important;
	box-shadow: 0px 0px 15px 0px #ddd;
	border-radius: 20px;
}
/* .specific-equip:hover{
	background: #eff0f3;
} */
.specific-equip:hover {
	border: 2px solid #00cfb4;

}

.generic-equip:hover {
	border: 2px solid #00cfb4;

}
/* .generic-equip:hover{
	background: #eff0f3;
} */
.image-selection {
	border: 3px solid #00b0f0;
}

.change-language input[type='radio']:checked + label::after {
	content: '\F5E1';
	display: inline-block;
	position: absolute;
	right: 16px;
	font-family: 'Material Design Icons';
	font-size: 26px;
	margin-top: -7px;
	color: #373935;
}
.h5,
h5 {
	font-size: 1.25rem;
}
.form-group {
	margin-bottom: 1rem;
}
label {
	font-weight: 600;
}
label {
	display: inline-block;
	margin-bottom: 0.5rem;
}
.btn-secondary {
	border-color: #3cb196;
	background: #3cb196 !important;
	color: #fff;
}
.btn {
	border-radius: 3px;
	font-size: 13px;
}
.waves-effect {
	position: relative;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	vertical-align: middle;
	z-index: 1;
	will-change: opacity, transform;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}
.btn-primary {
	background-color: #0c3823;
	border: 1px solid #0c3823;
	color: #fff;
	border-radius: 3px;
	padding: 5px;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary,
.btn-outline-primary.active,
.btn-outline-primary:active,
.show > .btn-outline-primary.dropdown-toggle,
.btn-outline-primary:hover,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
	/*background-color: transparent;*/
	border: 1px solid #0c3823;
	color: #000;
}
.btn {
	padding: 0.2rem 1.5rem;
}
.required {
	border: 1px solid #f47d7d;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 auto 7px;
	font-weight: 700;
	font-family: 'Montserrat', sans-serif;
}
.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
}
.custom-control-label {
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");
}
.custom-control-label::after {
	position: absolute;
	top: 0.25rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	content: '';
	background: no-repeat 50%/50% 50%;
}
.custom-control-input {
	position: absolute;
	left: 0;
	z-index: -1;
	width: 1rem;
	height: 1.25rem;
	opacity: 0;
}
input[type='checkbox'],
input[type='radio'] {
	box-sizing: border-box;
	padding: 0;
}
.custom-control-label::before {
	position: absolute;
	top: 0.25rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	content: '';
	background-color: #fff;
	border: #adb5bd solid 1px;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
	box-shadow 0.15s ease-in-out;
}
.custom-checkbox .custom-control-label::before {
	border-radius: 0.25rem;
}
.custom-control {
	position: relative;
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
	background: #508aeb;
}
.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: #007bff;
	background-color: #007bff;
}
.custom-checkbox .custom-control-label::before {
	border-radius: 0.25rem;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
	box-shadow 0.15s ease-in-out;
}
.custom-control-label::before {
	position: absolute;
	top: 0.25rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	content: '';
	background-color: #fff;
	border: #adb5bd solid 1px;
}
.disable-form {
	pointer-events: none;
	opacity: 0.7;
	/*background-color: #efeded80*/;
	cursor: col-resize;
}
.disable-form_equip {
	pointer-events: none;
	opacity: 0.6;
	/*background-color: #efeded80*/;
	cursor: col-resize;
}
.enable-form {
	pointer-events: all;
	opacity: 1;
	background-color: #fff;
}
#wrapper {
	height: 100%;
	overflow: hidden;
	width: 100%;
}
/*btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .btn-secondary.focus, .btn-secondary:active, .btn-secondary:focus, .btn-secondary:hover, .open > .dropdown-toggle.btn-secondary, .btn-secondary.active, .btn-secondary:active, .show > .btn-secondary.dropdown-toggle {
	border-color: #3cb196;
	background: #3cb196 !important;
	color: #fff;
}*/

.icon-vertical-center {
	/* vertical-align:middle; */
}
.topbar {
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 999;
	background: #404e67;
}
.topbar .topbar-left .logo {
	line-height: 50px;
}
.logo img {
	max-width: 60px;
	margin-top: -3px;
}
.ion-navicon:before {
	content: '';
}
.fixed-left.fixed-left-void .ion-navicon {
	display: block;
}
.topbar-left .button-menu-mobile i {
	font-size: 20px !important;
	color: #fff;
	vertical-align: middle;
	min-width: 30px;
}

.button-menu-mobile {
	line-height: 50px;
	vertical-align: middle;
	background: transparent;
	border: none !important;
	min-height: 50px;
}
.spinner-color {
	color: green;
}

.create-equip-card {
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0px 0px 14px 4px #ddd;
	padding-top: 20px;
	padding-bottom: 15px;
	margin-top: 0px;
	margin-bottom: 15px;
	margin-right: 99px;
	margin-left: 99px;
}
.padding-left-right {
	padding-left: 30px;
	padding-right: 30px;
}

.count-span {
	color: #e31b1a;
	background: #e8c8c7;
	width: 40px;
	height: 40px;
	display: inline-block;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	font-size: 22px;
	margin-right: 12px;
}

.equip-section-bottom {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0px 0px 14px 4px #ddd;
	padding: 20px 30px;
	margin: 0 auto 15px;
}
.input-phone{
	padding-left: 5px !important;
	padding-left: 5px !important;
	width: 100% !important;
	height: 30px !important;
	border-bottom: 1px solid rgba(227,27,26,.5) !important;
}
.input-phone-valid{
	border-bottom: 1px solid #000;
}
.flag-dropdown{
	display:none;
}
.css-tlfecz-indicatorContainer{
	padding: 5px !important;
}
.css-1gtu0rj-indicatorContainer{
	padding: 5px !important;
}
.header-background{
	background: rgb(193, 228, 220)
}
.topbar-breadcrumbs {
	/*background: #C1E4DC;
	margin: 0;
	padding: 4px 25px;
	box-shadow: 0px 0px 10px 1px #ddd;
	z-index: 9;
	font-size: 12px;
	color: #000;
	width: 100%;
	left: 0px;
	height: 35px;*/
}
.close {
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .5;
	margin-left: 0px !important;
}
button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.org-info-header{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.financial-box{
	border: 1px solid rgb(241 239 239)
}
.button-height{
	height: 30px;
	width: 155px;
	font-weight: 600;
}
.page-content-wrapper {
	margin: 0 0px;
}
.shade {
	background: #494c56e0;
}
.profile-bar {
	border: none !important;
	border-radius: none !important;
	background: url(https://cgrdc.co.in/vhub/assets/images/users/DRY-VAN-stock.webp) no-repeat center top;
	padding: 0px;
	margin: 0px -5px;
	color: #fff;
	background-size: 100% auto !important;
}
.container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
	width: 100%;
	padding-right: 5px;
	padding-left: 5px;
	margin-right: auto;
	margin-left: auto;
}
.profile-bar .container {
	padding: 20px 30px;
}
.row-flex {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.small, small {
	font-size: 80%;
	font-weight: 400;
}
.profile-bar .container {
	padding: 20px 140px;
}
.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.fa.fa-star, .fa-star-half-stroke {
	color: #eec50f;
}
.favourities {
	margin: 0 auto 7px;
	cursor: pointer;
}
.rating i {
	color: #eec50f;
	font-size: 16px;
}
.border-left {
	border-left: 1px solid #dee2e6!important;
}
.border-right {
	border-right: 1px solid #dee2e6!important;
}
.profile-bar .btn {
	padding: 8px 10px;
}
.btn-light {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.text-right {
	text-align: right!important;
}
.edit-equipment-btn{
	background-color: #15c39a;
	border: 1px solid #15c39a;
	color: #fff;
}
.profile-bar .btn {
	min-width: 240px;
}
.equip-view-button-group{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.wrapper-nav {
	position: relative;
	margin: 0 auto;
	overflow: hidden;
	padding: 0px;
	height: 35px;
	background: #404e67;
	width: 88%;
}
.profile-tabs .nav-tabs .nav-item.active {
	font-weight: 700;
	color: #000;
}
.profile-tabs .nav-tabs .nav-item {
	border-radius: 0px;
	min-width: 150px;
	text-align: center;
}
.nav-tabs .nav-link.active {
	color: #000;
	background-color: #fff;
	border-color: none;
}
.nav-tabs .nav-link.tabactive{
	color: #000 !important;
	background-color: #fff !important;
	border-color: none;
	font-weight: bold;
}
.profile-tabs .nav-tabs .nav-link {
	color: #fff !important;
}
.profile-tabs .nav-tabs .nav-item {
	border-radius: 0px;
}
.nav-tabs {
	border: 1px solid #dee2e6;
	border-radius: 5px;
}
.searchnav {
	display: inline-block;
}
.nav-tabs .nav-link.active {
	color: #000 !important;
	background-color: #fff;
	border-color: none;
	font-weight: bold;
	border-radius: 5px;
}
.bg-white {
	background-color: #ffffff !important;
}
.icon-size i {
	/*font-size: 35px;
	color: #e31b1a;*/
}
.icon-size label {
	color: #7d7d7d;
}
.mdi-repeat:before {
	content: "\F456";
}
.icon-bg {
	background: #e31b1a;
	color: #fff;
	font-size: 35px;
	/*position: absolute;*/
	top: -2px;
	left: -13px;
	display: inline-block;
	width: 50px;
	height: 64px;
	line-height: 64px;
}
.mdi {
	display: inline-block;
	font: normal normal normal 24px/1 "Material Design Icons";
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}
.icon-size label {
	color: #7d7d7d;
}
.fa.fa-calendar:before {
	content: "\f073";
}
.dated-i1 input {
	/*background: transparent !important;
	border: none;
	font-size: 15px;
	font-weight: 500;
	padding: 1px 0px 1px 0px;
	margin: 0px;
	width: 86%;
	border-radius: 0px;
	height: 25px;
	text-align: left;
	color: #000;*/
}
.dated-i input {
	background: transparent !important;
	border: none;
	font-size: 15px;
	padding: 1px 0px 1px 0px;
	margin: 0px;
	width: 100%;
	border-radius: 0;
	height: 24px;
	text-align: left !important;
}
input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
	-webkit-appearance: listbox;
}
.icon2-bg {
	background: #e31b1a;
}
.dated-inum input {
	background: transparent !important;
	border: none;
	font-size: 15px;
	padding: 1px 0px 1px 0px;
	margin: 0px;
	/*width: 50px;*/
	border-radius: 0;
	height: 24px;
	color: #fff;
	text-align: center;
}
.searchbtn {
	background: #e31b1a;
	height: 100%;
	line-height: 120px;
	text-align: center;
	font-size: 20px;
	color: #fff;
	font-weight: bold;
}
.searchbtn a {
	color: #fff;
	display: block;
	text-decoration: none;
}
.footer-text {
	position: relative;
	border: #fff !important;
	background: #fff;
	width: 200px;
	margin: 0 auto;
	bottom: -128px;
	height: 100px;
	margin-top: -100px;
	border-radius: 0 0 100px 100px;
	color: #e31b1a;
	font-size: 19px;
	font-weight: 500;
	line-height: 20px;
	padding-top: 10px;
	text-align: center;
}
.mdi-repeat:before {
	content: "\F456";
}
.list {
	position: absolute;
	/* left: 15px; */
	top: 0px;
	margin-top: 0px;
}
#myTabContent {
	background: #fff;
	width: 88%;
	margin: auto;
}
section {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0px 0px 14px 4px #ddd;
	padding: 20px 30px;
	margin: 0 auto 15px;
}
section hr {
	/*margin: 0 -30px 12px;*/
}
hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	/*border: 0;
	border-top: 1px solid rgba(0,0,0,.1);*/
}
hr {
	box-sizing: content-box;
	/*height: 0;*/
	overflow: visible;
}
.details-head {
	display: block;
}
.profile-tabs label {
	margin-bottom: 20px;
}
.img-fluid {
	max-width: 100%;
	height: auto;
}
.general-details-photos a img {
	width: 125px;
	border: 1px solid #ddd;
}
.d-inline-block {
	display: inline-block!important;
}
.general-details-photos a{
	margin: 8px;
}
.btn-primary {
	background-color: #15c39a;
	border: 1px solid #15c39a;
	color: #fff;
}
.btn-warning {
	background-color: #ffbe4e;
	border: 1px solid #ffbe4e;
	color: #000;
}
.Availabilities-table tr td:nth-child(1) {
	position: absolute;
	margin-top: -30px;
	width: 82%;
	background: #f4f4f4;
	padding-top: 10px;
	padding-bottom: 3px;
	font-weight: 600;
	font-size: 16px;
}
.Availabilities-table tr td {
	padding: 50px 5px 20px 5px;
	margin: 0px !important;
}
.Availabilities-table tr td {
	padding: 50px 5px 20px 5px;
	margin: 0px !important;
}
.Availabilities-table-tr-td {
	width: 200px;
}
.Availabilities-table label {
	display: block;
	margin: 0 0 3px 0;
}
.Availabilities-table label small {
	display: block;
}
.Availabilities-table .row {
	margin: 0px;
}
pl-4, .px-4 {
	padding-left: 1.5rem!important;
}
.pr-2, .px-2 {
	padding-right: 0.5rem!important;
}
.Availabilities-table .fa.fa-map-marker:nth-child(1)::after {
	content: " ";
	width: 2px;
	height: 30px;
	display: block;
	padding-left: 5px;
	border-right: 1px dotted #000;
}
.fa-map-marker:before {
	content: "";
}
.Availabilities-table tr td {
	padding: 50px 5px 20px 5px;
	margin: 0px !important;
}
.Availabilities-table-tr-td-last {
	width: 250px;
}
.table-nostyle {
	width: 100%;
}
.text-primary {
	color: #15c39a !important;
}
Availabilities-table tr td {
	padding: 50px 5px 20px 5px;
	margin: 0px !important;
}
.table-hover tbody tr:hover {
	background-color: #B7D9F3;
}
.table-hover tbody tr:hover {
	color: #212529;

}
.pricing .pricing-text {
	font-size: 20px;
	display: block;
	font-weight: 600;
	margin: 0 auto 10px;
}
.btn-outline-primary:hover {
	background-color: #15c39a;
	border: 1px solid #15c39a;
	color: #fff;
}
/* .btn:hover {
	color: #212529;
	text-decoration: none;
} */
.btn-outline-dark:hover{
	background-color: #ffbe4e;
	color: #fff;
}
.pricing img {
	max-width: 60px;
	margin: 0 auto 10px;
}
.btn {
	padding: 0.2rem 1.5rem;
}

.history-table img {
	max-width: 30px;
	margin-right: 10px;
}
.table-striped tbody tr:nth-of-type(odd), .thead-default th {
	background-color: #eff3f6;
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0,0,0,.05);
}
.document-private {
	width: 160px;
}
.icon-green {
	color: #15c39a;
}
.icon-red {
	color: #E31B1A;
}
.history-icons i {
	font-size: 25px;
	margin: 0 6px;
}
.document-private {
	width: 160px;
}
.history-icons {
	width: 110px;
}
.doc-row-height{
	height: 50px;
}
table.tables {
	border: 1px solid #ccc;
	border-collapse: collapse;
	margin: 0;
	padding: 0;
	width: 100%;
	table-layout: fixed;
	font-size: 13px;
	margin-bottom: 15px;
}
table.tables tr {
	background-color: #f8f8f8;
	border: 1px solid #ddd;
	padding: 0.35em;
}
table.damage-table th, table.damage-table td {
	padding-bottom: 15px;
	padding-top: 15px;
}
table.tables th {
	letter-spacing: .1em;
	background: #e4e4e4;
	font-weight: 500;
}
table.tables th, table.tables td {
	padding: 0.4em;
	text-align: left;
}

.custom-select {
	height: auto !important;
	/*padding: 3px 10px !important;*/
	font-size: inherit !important;
}
.boxed {
	padding: 0px 16px;
}
.bs-example-modal-center-new-document .fallback input {
	height: 179px;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	font-size: 0px;
	padding: 0px !important;
	margin: 0px !important;
	opacity: 0;
}
.boxed .col-12 {
	border: 2px dashed #ddd;
	padding: 20px;
}
.table-hover tbody tr:hover {
	background-color: #B7D9F3;
}
.flat-picker{
	width: 100%;
}

.avail-text-field{
	width: 80%;
	display: inline;
}
.col-form-label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: 13px;
	line-height: 1.5;
}
.equip-pricing-text {
	font-size: 20px;
	display: block;
	font-weight: 600;
	margin: 0 auto 10px;
}
.shadow {
	box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
.damage-image{
	width: 50px;
	height: 50px;
}

.custom-select1 {
	height: auto !important;
	padding: 3px 10px !important;
	font-size: inherit !important;
}
.custom-control-label::before, .custom-file-label, .custom-select1 {
	transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom-select1 {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	/* background: #fffurl(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e) no-repeat right .75rem center/8px 10px; */
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
#hidden_div2 label, #hidden_div2 small, #hidden_div label, #hidden_div small, #hidden_divi2 label, #hidden_divi2 small, #hidden_divi label, #hidden_divi small {
	display: block;
}
.btn-group .btn.btn-default.active {
	background: #fff;
	border-radius: 10px;
}
.btn-group .btn.btn-default.active {
	background: #ccc;
}
.signup-container {
	width: 68%;
	padding-right: 100px;
	padding-left: 100px;
	margin-right: auto;
	margin-left: auto;
}
input[type='checkbox']:checked + label:after {
	content: '✔';
	display: inline-block;
	font-size: 1em;
	color: white;
	padding-left: 0.2em;
}
.css-2rsrq8 {
	width: 90%;
	height: 39px;
	padding: 8px;
	border: 1px solid rgb(213, 213, 213);
	border-radius: 4px;
	background: rgb(255, 255, 255);
	box-shadow: none;
	font-family: inherit;
	font-size: 14px;
	border-bottom: 1px solid rgba(227, 27, 26, 0.5);
}
.btn-primary:hover{
	background-color: #029572;
	border-color: #029572;
	color: #fff;
}
.btn-group .btn.btn-default.active {
	background: #fff;
	border-radius: 10px;
}
.btn-group.w-100 {
	background: #dedede;
	margin: 0 auto 20px;
	padding: 5px 10px;
	border-radius: 10px;
}

.w-100 {
	width: 100%!important;
}
#hidden_div2 .estimate i, #hidden_div .estimate i, #hidden_divi2 .estimate i, #hidden_divi .estimate i {
	background: #ddd;
	padding: 10px;
	border-radius: 50%;
	display: inline-block;
	width: 34px;
	height: 34px;
	text-align: center;
	padding: 0px;
	line-height: 34px;
}
#hidden_div2 p, #hidden_div p, #hidden_divi2 p, #hidden_divi p {
	display: inline-block;
	margin: 0 0 0 8px !important;
	vertical-align: middle;
}
#date-range1 {
	border: 1px solid #ddd;
	border-radius: 3px;
	margin: 0 15px;
	padding: 6px 0px;
}
.paragraph{
	padding-left: 10px;
	font-size: 15px;
}
.container-fluid-padding{
	padding-left: 15px;
	padding-right: 15px;
}
.was-validated .form-control:invalid, .form-control.is-invalid{
	background-image:none;
}
.was-validated .form-control:valid, .form-control.is-valid{
	background-image:none;
}

.bg-primary {
	background-color: #404e67 !important;
}
.pl-3, .px-3 {
	padding-left: 1rem!important;
}
.pr-3, .px-3 {
	padding-right: 1rem!important;
}
.three-tab{
	/*background-color: #FFF;*/
}
.organization-picture-container img {
	width: 80px;
	border: 1px solid #ddd;
	height: 80px;
	padding: 5px;
	border-radius: 50%;
}
.input-cus-style {
	background: #ebf7f4;
	padding: 6px 20px;
	display: block;
	text-align: left;
}
.input-cus-right button {
	margin-top: -35px;
	margin-right: 20px;
}

.btn-danger {
	background-color: #E31B1A;
	border: 1px solid #E31B1A;
}
.owner-service-fees-table {
	box-shadow: 0px 0px 14px 4px #ddd;
	border-radius: 10px;
	min-height: 130px;
}
.owner-service-fees-table input {
	max-width: 40px;
	font-weight: 900;
}
.cus-input-file input {
	height: 70px !important;
	line-height: 70px;
	font-size: 0px !important;
	background: transparent !important;
	opacity: 0;
	margin: 0px;
}

.financial-browse-button{
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px dotted #000;
	font-weight: 500;
	cursor: pointer;
	margin-bottom: 5px;
}
.document-box{
	display: flex !important;
	flex-direction: column;
	/*border: 1px solid #f9f9f9;*/
	padding: 10px;
	width: 100px;
}
.documents-gallary{
	overflow: auto;
	/*border:1px solid #f9f9f9;*/
	margin-top: -10px;
}
.hori-timeline .events{
	border-top:2px dashed #404e67;
}
.hori-timeline .events .event-list .event-date {
	/*position: absolute;
	top: 38px;
	right: 0;
	width: 20px;
	height: 20px;
	margin: 0 auto;
	border-radius: 50%;
	padding: 4px;*/
}
.bg-radial-gradient-primary {
	/* background-color: #1bc5bd; */
	/*background-image: radial-gradient(circle,#404e67 40%, #fff 40%);
	border: 1px solid #404e67;*/
}
.list1:before {
	 content: "";
	 position: relative;
	display: inline-block;
	 height: 36px;
	 border-right: 2px dashed #404e67;
	 top: 0;
 }
.list2:before {
	content: "";
	position: relative;
	display: inline-block;
	height: 36px;
	border-right: 2px dashed #404e67;
	top: 0;
}
.list3:before {
	content: "";
	position: relative;
	display: inline-block;
	height: 36px;
	border-right: 2px dashed #404e67;
	top: 0;
}
.hori-timeline .events .event-list .event-date {
	position: relative;
	top: -50px;
	left:96%;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	padding: 4px;
	overflow-wrap: anywhere;
}

.hori-timeline .events .event-list1 .event-date1 {
	position: relative;
	top: -50px;
	left: -3%;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	padding: 4px;
	overflow-wrap: anywhere;
}

@media (max-width: 1024px) { 
	.hori-timeline .events .event-list1 .event-date1 {
		position: relative;
		top: -70px !important; 
		left: -3%;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		padding: 4px;
		overflow-wrap: anywhere;
	}
 }


.hori-timeline .events .event-list .event-date2 {
	position: relative;
	top: -50px;
	/* right: -2px; */
	width: 20px;
	height: 20px;
	margin: 0 auto;
	border-radius: 50%;
	padding: 4px;
	overflow-wrap: anywhere;

}
.bg-radial-gradient-primary {
	/* background-color: #1bc5bd; */
	background-image: radial-gradient(circle,#404e67 40%, #fff 40%);
	border: 1px solid #404e67;
}
.hori-timeline .events .event-list .event-date1 {
	position: absolute;
	top: -10px;
	right: 5px;
	width: 20px;
	height: 20px;
	margin: 0 auto;
	border-radius: 50%;
	padding: 4px;
}
.bg-radial-gradient-primary1 {
	/* background-color: #1bc5bd; */
	background-image: radial-gradient(circle,#404e67 40%, #fff 40%);
	border: 1px solid #404e67;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}
.flatpickr-input[readonly] {
	cursor: pointer;
	background: white;
}
.financial-button{
	border: 1px solid #999;
}
.financial-button:hover{
	border: 1px solid darkgreen;
}
.financial-download-label{
	font-size: 9px;
	padding-top: 8px;
	cursor: pointer;
}
.financial-download-image{
	cursor: pointer;
	width: 30px;
}
.equip-public-favor{
	margin-left: 0px !important;
}
.border-right {
	border-right: 1px solid #dee2e6!important;
}
.counter-box {
	background: #fff;
	border-radius: 10px;
	box-shadow: 0px 0px 14px 4px #ddd;
	margin: 0 auto 15px;
	border: 1px solid #ddd;
	padding:15px 10px;
	min-height: 87px;
}
.counter-box:hover {
	cursor: pointer;
	background-color:#e4e4e4
}

.counter1 .fa {
	color: #ffbe4e;
	width: 40px;
	height: 40px;
	background: #ffbe4e36;
	border-radius: 50%;
	line-height: 40px;
	font-size: 23px;
	margin-right: 10px;
}
.counter-box .row {
	padding: 0px !important;
	margin: 0px !important;
}
.counter-indi-box p {
	font-size: 11px;
	font-weight: 600;
	padding-top: 0px;
	color: #404e67;
}
.pb-4, .py-4 {
	padding-bottom: 1.5rem!important;
}
.pt-4, .py-4 {
	padding-top: 1.5rem!important;
}
.pl-1, .px-1 {
	padding-left: 0.25rem!important;
}
.pr-1, .px-1 {
	padding-right: 0.25rem!important;
}
.fa-user:before {
	content: "";
}
.counter {
	/* background: #febd4e; */
	font-size: 22px;
	font-weight: 500;
	/* border-radius: 10px 0 0 10px; */
	/* padding-top: 30px !important; */
	line-height: 20px;
	/* margin: 0 auto 5px !important; */
	/* display: block; */
	color: #000;
	letter-spacing: 3px;
}
.yellow-color{
	background-color: yellow;
}
.form-control:disabled {
	background-color: #e9ecef !important;
	opacity: 1;
}
.bg-dark {
	background-color: #343a40!important;
}
.text-white {
	color: #ffffff !important;
}
.text-black{
	color:#000 !important;
}
.splitter-layout{
	height: auto !important;
	padding-right: 0px;
}
.splitter-layout > .layout-splitter{
	height: auto;
	width: 5px;
	background: grey;
}
.splitter-layout > .layout-splitter{
	height:"100vh";
  }
.reservation-tabs .nav.nav-tabs .nav-link, .reservation-tabs1 .nav.nav-tabs .nav-link {
	padding: 5px 0px;
	border-radius: 0px;
	color: #fff;
}
.hyperlinktab{
	text-decoration:none !important;
}
.search-db:focus{
	color: #FFF !important;
}
.search-border{
	border: 1px solid #FFF !important;
	padding: 5px;
}
@media (max-width: 767.98px){
	.mo-bg-dark {
		background-color: #393c47 !important;
	}
}
@media (max-width: 767.98px){
	.searchbtn {
		line-height: 40px;
		max-width: 110px;
		text-align: center;
		margin: 20px auto 0px;
		height: 40px;
	}
}

.cbtn-success {
	background: #3cb196;
	border: 1px solid #3cb196 !important;
}

.cbtn-success:hover {
	color: #212529;
	background-color: #3cb196;
	border-color: #3cb196;
}

.cbtn {
	color: #fff;
	padding-top: 2px !important;
	border-radius: 20px !important;
	width: 100px;
	height: 30px;
}
.grid-button:focus{
	background-color: #15c39a;
}
.ag-center-cols-viewport{
	overflow-x:hidden;
}
.row.fixed {
	position: fixed;
	z-index: 999;
	bottom: 0px;
	width: 100%;
	left: 0px;
	background: #404e67;
	margin: 0 0px 0px 0px;
	padding: 10px;
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>");
}
.carousel-control-next-icon, .carousel-control-prev-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: no-repeat 50%/100% 100%;
}
#output {
	min-width: 300px;
	border: 1px solid #eeebeb;
	min-height: 150px;
	margin: 20px auto 20px;
	text-align: center;
	max-width: 100%;
	/*background: url('./images/upload-camera.png') no-repeat center center;
	 background-repeat: no-repeat; url('./images/banner1.png');*/
}
.camera-class{
	min-width: 300px;
	border: 1px solid #eeebeb;
	min-height: 150px;
	margin: 20px auto 20px;
	text-align: center;
	max-width: 100%;
	/*background: url('./images/upload-camera.png') no-repeat center center;*/
}
.licese-class{
	font-size: 35px;
	width: 80%;
	text-align: center;
	padding: 0px;
	height: 50px;
	padding-right: 0px !important;
}
#file-input {
	width: 60px;
	height: 60px;
	line-height: 60px;
	border-radius: 50%;
	font-size: 30px;
	padding: 0px;
	background: #ed1111;
	border: 1px solid #000;
	color: #fff;
	/* box-shadow: 0px 0px 0px 4px #ed1111; */
	vertical-align: top;
	margin: 0 5px 20px;
}

.take-camata-class{
	width: 60px;
	height: 60px;
	line-height: 60px;
	border-radius: 50%;
	font-size: 30px;
	padding: 0px;
	background: #ed1111;
	border: 1px solid #000;
	color: #fff;
	/* box-shadow: 0px 0px 0px 4px #ed1111; */
	vertical-align: top;
	margin: 0 5px 20px;
}

.retake-image {
	width: 60px;
	height: 60px;
	line-height: 60px;
	border-radius: 50%;
	font-size: 30px;
	padding: 0px;
	background: #ffb73b;
	border: 1px solid #000;
	color: #000;
	/* box-shadow: 0px 0px 0px 4px #ff6161; */
	vertical-align: top;
	margin: 0 5px 20px;
}

.ion-ios7-camera-outline:before {
	content: "";
}

.ion-ios7-camera-outline{
	display: inline-block;
	font-family: "Ionicons";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	text-rendering: auto;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}
#multiple-capture-image #file-input2 i, #multiple-capture-image #file-input3 i, #multiple-capture-image #file-input4 i, #multiple-capture-image #file-input5 i, #multiple-capture-image #file-input6 i, #multiple-capture-image #file-input7 i, #multiple-capture-image #file-input8 i {
	width: 80px;
	height: 50px;
	/*border: 2px dashed #929292;*/
	line-height: 50px;
	vertical-align: top;
	text-align: center;
	font-size: 40px;
	margin: 10px 10px;
}
.tire-class{
	border: 1px solid #f8f1f1;
	display: flex;
	align-items: center;
	margin-bottom: 1px;
}
.mobile-button-height{
	height: 50px;
	width: 100px;
}
#multiple-capture-image .field {
	border: 1px solid #ddd;
	margin: 0 auto 25px;
}
.units .nav-tabs .nav-link.active {
	color: #fff !important;
	background-color: red;
	border-color: none;
	font-weight: bold;
}
.star-rating {
	/*background: #f6f6f6;*/
	font-size: 30px;
	padding: 10px;
}
.icon1 {
	background: #ed1111;
	width: 100px;
	height: 100px;
	line-height: 100px;
	border-radius: 50px;
	color: #fff;
	font-size: 50px;
	display: inline-block;
}

.retake-photo{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

}
.css-2613qy-menu{
	overflow: auto;
}
.myProfilePadding{
	padding-bottom: 14px;
}
@media (min-width: 768px){
	.Damages-list li {
		max-width: 49%;
		display: inline-block;
	}
}
@media (max-width: 768px){
	.list {
		min-width: 3500px;
	}
}

.profile-tabs .scroller {
  text-align: center;
  cursor: pointer;
  padding: 7px;
  padding-top: 13px;
  white-space: no-wrap;
  vertical-align: middle;
  background-color: #fff;
}
.profile-tabs .wrapper-nav, .list-details-tabs .wrapper-nav, .list-details-cus-tabs .wrapper-nav {
  position:relative;
  margin:0 auto;
  overflow:hidden;
  padding:0px;
  height:35px;
  background: #404e67;
}
@media (max-width: 1300px) {
	.profile-tabs .wrapper-nav, .list-details-tabs .wrapper-nav, .list-details-cus-tabs .wrapper-nav {
		height:45px;
	}
}
@media (max-width: 1150px) {
	.profile-tabs .wrapper-nav, .list-details-tabs .wrapper-nav, .list-details-cus-tabs .wrapper-nav {
		height:70px;
	}
}
@media (max-width: 1000px) {
	.profile-tabs .wrapper-nav, .list-details-tabs .wrapper-nav, .list-details-cus-tabs .wrapper-nav {
		height:90px;
	}
}
.inputMandatory{
	border-bottom: 1px solid rgba(227, 27, 26, 0.5) !important;
}
.inputNotMandatory{
	border: 1px solid #ccc !important;
}
.phone-valid{
	padding-left: 5px !important;
	padding-left: 5px !important;
	width: 100% !important;
	height: 30px !important;
}
.phone-valid-invalid{
	padding-left: 5px !important;
	padding-left: 5px !important;
	width: 100% !important;
	height: 30px !important;
	border-bottom: 1px solid rgba(227, 27, 26, 0.5)
}
::-ms-reveal {
  display: none
}

.tip-align{
	text-align: right;
	font-weight: 600;
}
.tip-padding{
	padding-left: 10px;
	height: 30px;
}
.reservation{
	background: white;
    color: black;
    border: 1px solid #e4e4e4;
    border-radius: 15px;
    box-shadow: 1px 2px #e4e4e4;
	margin-top: 5px;
}
.reservation:hover{
	border: 1px solid green;
	background: green;
	color: white;
}

.select-reservation{
	border: 1px solid #e4e4e4;
	background: #23D2C1;
	color: black;
	box-shadow: none;
}
.filter-button-border{
	border: 0px !important;
}


.res-table{
	background-color: #fff;
	width: 100%;
}
.res-table.child{
	background-color: #fff;
	border: 1px solid #cfcfcf;
}
.table-heading{
	font-size: 16px;
	font-weight: bold;
	color: #000;
	margin-bottom: 10px;
}

.res-table .map{
	margin:  0 10px;
}

.res-table tr td{
	padding: 5px;
}
.reservation-title{
	font-size: 14px;
	font-weight: bold;
	color: #000;
	width: 120px;
}
.res-table .fa.fa-map-marker:first-child:after{
	border-right: 1px dotted #000;
    content: " ";
    display: block;
    height: 30px;
    padding-left: 5px;
    width: 2px;
}
.res-table:hover{
	background-color: #f6faff;
}
.r-date{
	display: contents;
}
.r-date .from{
    text-align: center;
    border: 1px solid #acafb2;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    width: 50px;
    margin: 0 auto;
}
.r-date .from h4,
.r-date .from span{
	display: block;
	margin-bottom: 0;
	font-weight: 500;
}

.r-date .from h4{
	font-size: 14px;
	font-weight: bold;
	color: #000;
	padding: 5px;
    background: #dee2e6;
}

.reserve .r-date .from h4{
	font-size: 14px;
	font-weight: bold;
	color: #fff;
	padding: 5px;
    background: #babfc7;
}


.reserve .r-date .from {
    text-align: center;
    border: 1px solid #acafb2;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    width: 50px;
    margin: 0 auto;
}

.res-date{
	margin-left: 5rem;
	margin-bottom: 1rem;
	margin-top: 1rem;
}


.res-date table{
	background-color: #e9ecef;
}
.address, .address-1{
	font-weight:500;
	padding-top: 10px;
    padding-bottom: 10px;
}
  
/* ****** BUTTON CLOSE **** */
.btn-close2 {
	margin: 0;
	border: 0;
	padding: 0;
	background: #0052cc !important;
	opacity: 1;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all 150ms;
	margin-top: 6px;
	margin-left: 6px;
  }
  .btn-close2 .icon-cross {
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	position: relative;
	width: 20px;
	height: 20px;
  }
  .btn-close2 .icon-cross:before, .btn-close2 .icon-cross:after {
	content: "";
	position: absolute;
	top: 6px;
	left: 0;
	right: 0;
	height: 6px;
	background: #fff;
	border-radius: 6px;
  }
  .btn-close2 .icon-cross:before {
	transform: rotate(45deg);
  }
  .btn-close2 .icon-cross:after {
	transform: rotate(-45deg);
  }
  .btn-close2 .icon-cross span {
	display: block;
  }
  .btn-close2:hover, .btn-close2:focus {
	transform: rotateZ(90deg);

	background: #404e67;
	-webkit-transform: rotateZ(90deg);
	-moz-transform: rotateZ(90deg);
	-ms-transform: rotateZ(90deg);
	-o-transform: rotateZ(90deg);
}
  
 
  

  .visually-hidden {
	position: absolute !important;
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0 !important;
	border: 0 !important;
	height: 1px !important;
	width: 1px !important;
	overflow: hidden;
  }
  .phoneNo .react-tel-input .form-control{
    padding-left:10px !important
 }
 .defaultRateWidth{
	width: 150px;
 }


