.App {
  /* text-align: center;*/
  width: 100%;
  height: 100vh;
  /* margin: -2px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-page {
  height: inherit;
}

.pageContent .container-fluid {
  padding-left: 14px;
}

.pages-group_page {
  display: block !important;
}

.main-section a {
  cursor: pointer;
}

/* grid styles */

.ag-theme-bootstrap .ag-cell-focus {
  border: none;
  outline: none;
}

.ag-theme-bootstrap .ag-header {
  /* border-bottom: solid 1px #ccc; */
}

.ag-theme-bootstrap .ag-header-cell {
  text-align: left;
  padding-left: 0 !important;
}

.ag-theme-bootstrap .ag-header-cell-text {
  padding-left: 5px;
  font-size: 13px;
}
.dateRange .flatpickr-input {
  color: #2f4267 !important;
  font-size: 0.9em;
  letter-spacing: 1px;
  font-family: inherit;
}
.dateRange .form-control {
  padding: 0 3px;
}
.ag-theme-bootstrap {
  font-size: 13px;
  color: #262526;
  letter-spacing: 1px;
}

.ag-theme-bootstrap .ag-row-odd {
  background-color: #fff !important;
  line-height: 26px;
}
.ag-theme-bootstrap .ag-row-even {
  background-color: #f6f6f6 !important;
  line-height: 26px;
}
.ag-theme-bootstrap .ag-row-selected,
.ag-theme-bootstrap .ag-row-selected:hover {
  background-color: #B7D9F3 !important;
  color: #333;
  line-height: 26px;
  border-bottom: solid 1px #4e8d07;
}
.ag-theme-bootstrap .ag-row:hover {
  background-color: #cfe2b9 !important;
  border-bottom: solid 1px #4e8d07;
  /*color: white;*/
}
.ag-theme-bootstrap .ag-floating-top .ag-row {
  background-color: #f0f0f0;
  line-height: 26px;
}

.ag-cell .ag-react-container a {
  font-weight: 700;
  color: #000;
}
.ag-row-selected .ag-react-container a {
  /*background-color: ##ff5252;*/
  color: #fff;
}

.ag-cell {
  cursor: default;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.ag-bl-full-height {
  overflow-x: hidden !important;
}

.ag-theme-bootstrap .ag-group-cell {
  font-weight: bold;
  font-style: normal;
}

.ag-theme-bootstrap .sick-days-breach {
  background-color: #00bcc9 !important;
}

.ag-sort-order {
  display: none;
}

.ag-theme-bootstrap .ag-cell-focus {
  border: none !important;
}

.ag-row-no-animation .ag-row {
  -webkit-transition: background-color 1s;
  -o-transition: background-color 1s;
  transition: background-color 1s;
  transition-property: background-color;
  transition-duration: 1s;
  transition-timing-function: initial;
  transition-delay: initial;
}

.ag-theme-bootstrap .ag-header-select-all,
.ag-theme-bootstrap .ag-header-cell-menu-button {
  margin-top: 0px !important;
  margin-left: 1px;
}

/* end of grid styles */

.customNotification {
  background-color: #f1da76;
  border-color: #e8a93f;
  max-width: 400px;
  max-height: 120px;
  padding-top: 5px;
}
.customNotification .notifyIcon {
  font-size: 40px;
  margin-top: 20px;
  margin-left: 5px;
}
.customNotification .message {
  font-size: 12px;
}
.customNotification .closeIcon {
  cursor: pointer;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 20px;
}
.customNotification .closeIcon:hover {
  color: #ff5252;
}
.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2017;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  transition: 0.5s;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  -o-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 0.5s linear infinite;
}

/* for grid checkbox */
.ag-icon-checkbox-unchecked,
.ag-icon-checkbox-indeterminate {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px !important;
  width: 20px !important;
  background: none !important;
  background-color: white;
  border: solid 1px #a8b5c4;
}
.ag-icon-checkbox-checked {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px !important;
  width: 20px !important;
  background: none !important;
  background-color: #4f8901 !important;
  border: solid 1px #4f8901;
}

.ag-icon-checkbox-checked:after {
  display: block;
  font-family: FontAwesome;
  content: '\f00c';
  color: white;
  position: relative;
  text-align: center;
  top: -1px;
}
.ag-selection-checkbox,
.ag-header-select-all {
  display: block;
  position: relative;
  top: 3px;
  left: 5px;
  padding-top: 0px !important;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 20px;
  line-height: 20px;
  text-align: left !important;
  margin-bottom: 0px !important;
  padding-bottom: 8px;
}
.ag-header-select-all .ag-icon {
  line-height: 20px;
}

.ag-theme-bootstrap .ag-ltr .ag-cell-no-focus,
.ag-theme-bootstrap .ag-cell-no-focus {
  border: none !important;
}
.ag-theme-bootstrap .ag-row > .ag-cell {
  line-height: 34px;
}
.ag-theme-bootstrap .ag-header-cell-label {
  top: 0px !important;
  font-weight: 600;
}

.ag-theme-bootstrap .ag-cell-no-focus {
  /* border-top: 1px solid transparent; */
  /* border-bottom: 1px solid transparent; */
}

/* end of header checkbox styles */
.Select-menu-outer {
  z-index: 1000 !important;
}

.tile-wrapper .tile .body-others {
  min-height: 40px !important;
  padding: 3px !important;
  height: calc(100% - 67px) !important;
}

.tile-wrapper .tile .body-history {
  min-height: 40px !important;
  padding: 3px !important;
  height: calc(100% - 34.5px) !important;
}

.logoWrapMobile {
  height: 100%;
}

.logoWrapMobile img {
  height: 100%;
}

.ag-cell .ag-react-container a {
  font-weight: normal;
  color: #006beb;
}

a {
  cursor: pointer;
}

/* for modal */

.modal-asset-bd.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.modal-asset-bd.fade {
  filter: alpha(opacity=0);
  opacity: 0.5;
}

.modal-asset-bd {
  position: fixed;
  opacity: 0.5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1051;
  background-color: #000;
}

.modal-asset {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1052;
}

.customAlertD {
  z-index: 1053 !important;
}

.dashboard {
  padding-left: 0px !important;
}
.ag-filter-select {
  height: 25px !important;
  width: 100% !important;
  margin: 0 !important;
}
/***asset-selection begins*********/
.driver-ui-container {
  background: #ffffff;
  background: -moz-linear-gradient(45deg, #ffffff 0%, #dbe4ed 99%);
  background: -webkit-linear-gradient(45deg, #ffffff 0%, #dbe4ed 99%);
  background: linear-gradient(45deg, #ffffff 0%, #dbe4ed 99%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dbe4ed', GradientType=1);
  border: solid 3px #ffffff;
  border-radius: 10px;
  width: 80%;
  min-height: 250px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.driver-ui-pageContent {
  background: #91bbd1;
  background: -moz-linear-gradient(
    45deg,
    #91bbd1 0%,
    #dce9ef 50%,
    #e4f5fc 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #91bbd1 0%,
    #dce9ef 50%,
    #e4f5fc 100%
  );
  background: linear-gradient(45deg, #91bbd1 0%, #dce9ef 50%, #e4f5fc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#91bbd1', endColorstr='#e4f5fc', GradientType=1);
}
.driver-alerts-page-content {
  background-color: #eef4f7;
  overflow: hidden;
  background: #eef4f7;
  background: -moz-linear-gradient(
    -45deg,
    #eef4f7 0%,
    #f4faff 53%,
    #dce9ef 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #eef4f7 0%,
    #f4faff 53%,
    #dce9ef 100%
  );
  background: linear-gradient(135deg, #eef4f7 0%, #f4faff 53%, #dce9ef 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eef4f7', endColorstr='#dce9ef', GradientType=1);
}
/***asset-selection ends***********/

.footerSection p.pull-left {
  font-size: 0.8em;
}

.footerSection a.pull-right {
  font-size: 0.8em;
  color: white;
}

.footerSection {
  position: fixed;
  bottom: 0px;
}

.donutLegend {
  display: none !important;
}

/*#myProgress {
  width: 200px;
  height:200px;
  background-color: #333333;
  text-align: center;
}*/
#myProgress {
  width: 150px;
  min-height: 60px;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  margin: 0 auto;
  left: 45%;
  top: 40%;
  border-radius: 15px;
}

#progress_txt {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.mandatory-field:before {
  content: '\f069';
  font-family: FontAwesome;
  font-style: normal;
  color: #394b61;
  padding: 0px;
  margin-right: 5px;
  font-size: 9px;
  position: relative;
  top: -3px;
}

.bolder {
  font-weight: bold !important;
}

.text-align-right {
  text-align: right !important;
  text-align: -moz-right !important;
  text-align: -webkit-right !important;
}
.pr-0 {
  padding-right: 0px !important;
}

@media only screen and (max-device-width: 280px) {
  .headerSection .select-company {
    width: 94px !important;
  }
  .headerSection .favorite-menu-button,
  .headerSection .main-menu-button {
    padding: 0px 0px !important;
    width: 40px !important;
    height: 35px !important;
  }
  .fixedToolbar .right-column .btn-group .btn {
    padding: 6px 10px !important;
  }
}
@media only screen and (min-device-width: 281px) and (max-device-width: 481px) {
  .headerSection .select-company {
    width: 174px !important;
  }
  .headerSection .favorite-menu-button,
  .headerSection .main-menu-button {
    padding: 0px 0px !important;
    width: 40px !important;
    height: 35px !important;
  }
  .fixedToolbar .right-column .btn-group .btn {
    padding: 6px 10px !important;
  }
}
@media only screen and (min-device-width: 482px) and (max-device-width: 1024px) {
  .min-width-250 {
    min-width: 250px !important;
  }
  .headerSection .select-company {
    width: 311px !important;
  }
}
@media only screen and (min-device-width: 1024px) {
  .min-width-250 {
    min-width: 250px !important;
  }
  .headerSection .select-company {
    width: 311px !important;
  }
}

.divCircle {
  border-radius: 50%;
  background: url('images/clock.png');
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  float: left;
  background-position: center center;
  transform: rotate(90deg);
}
.divCircleBG {
  border-radius: 50%;
  background: url('images/clock-bg.png');
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  float: left;
  background-position: center center;
  transform: rotate(90deg);
}
.outerCircle {
  background: url('images/directionWidgetBackground.png');
  width: 84px;
  height: 84px;
  border-radius: 10px;
}
.speedText {
  padding: 3px;
  font-size: 0.9em;
  font-weight: bold;
  margin-top: 25px;
}
td.directionImageWraper {
  height: 100%;
  vertical-align: middle !important;
  text-align: center;
}
td.directionImageWraper img {
  height: 64px;
  width: 64px;
  margin: 0 auto;
}
td.directionImageWraper .speedText {
  padding: 3px;
  font-size: 0.9em;
  font-weight: bold;
  transform: rotate(180deg);
}
.directionText {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
.textCircle {
  width: 89px;
  float: left;
  margin-top: -40px;
  margin-left: 12px;
  font-size: x-small;
  position: relative;
  color: black;
}
.custom-tab-control-wrap .nav-tabs {
  border: none;
}
.custom-tab-control .btn-group {
  border-radius: 25px;
  padding: 3px;
  background-color: white;
  border: solid 1px #cfd8e3;
  height: 34.5px;
}
.custom-tab-control .btn-group .btn-default {
  background-color: transparent;
  transition: background-color 500ms;
  border: solid 1px transparent !important;
  border-radius: 25px !important;
  padding: 0px;
  font-size: 0.9em;
  box-shadow: none;
  color: #263241;
}
.custom-tab-control .btn-group .btn-default:hover {
  background-color: #c7e3a1;
}
.custom-tab-control .btn-group .btn-default.active {
  background-color: #bcdd8e;
  border: solid 2px white;
  transition: background-color 1000ms;
}
.customForm p.readOnlyText {
  font-size: 13px;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 5px;
}
span.highcharts-subtitle {
  margin-top: 20px !important;
}
.grid-download-dropdown + ul {
  right: 0px !important;
}
.ag-root-wrapper-body .ag-pinned-left-cols-viewport {
  overflow-x: scroll;
  overflow-y: hidden;
}
.customTableCluster {
  min-width: 0px;
  border: solid 1px #dfe5ec;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.customTableCluster .table-header {
  padding: 10px;
  background-color: #cfd8e3;
}
.customTableCluster .table-header .text {
  font-size: 1.2em;
}
.customTableCluster .table-responsive {
  flex: 1;
}
.customTableCluster .table-responsive table {
  margin-bottom: 0px;
}
.customTableCluster .table > thead > tr > th,
.customTableCluster .table > tbody > tr > th,
.customTableCluster .table > tfoot > tr > th,
.customTableCluster .table > thead > tr > td,
.customTableCluster .table > tbody > tr > td,
.customTableCluster .table > tfoot > tr > td {
  position: relative;
  white-space: nowrap;
  font-size: 0.9em;
}
.customTableCluster .table > thead > tr > th input[type='checkbox'],
.customTableCluster .table > tbody > tr > th input[type='checkbox'],
.customTableCluster .table > tfoot > tr > th input[type='checkbox'],
.customTableCluster .table > thead > tr > td input[type='checkbox'],
.customTableCluster .table > tbody > tr > td input[type='checkbox'],
.customTableCluster .table > tfoot > tr > td input[type='checkbox'] {
  width: 16px;
  height: 16px;
  top: 5px;
  position: absolute;
  cursor: pointer;
}
.customTableCluster .table > thead > tr > th .btn-default,
.customTableCluster .table > tbody > tr > th .btn-default,
.customTableCluster .table > tfoot > tr > th .btn-default,
.customTableCluster .table > thead > tr > td .btn-default,
.customTableCluster .table > tbody > tr > td .btn-default,
.customTableCluster .table > tfoot > tr > td .btn-default {
  border: solid 1px #006beb;
  color: #006beb;
}
.customTableCluster .table > thead > tr > th .btn-default .fa,
.customTableCluster .table > tbody > tr > th .btn-default .fa,
.customTableCluster .table > tfoot > tr > th .btn-default .fa,
.customTableCluster .table > thead > tr > td .btn-default .fa,
.customTableCluster .table > tbody > tr > td .btn-default .fa,
.customTableCluster .table > tfoot > tr > td .btn-default .fa,
.customTableCluster .table > thead > tr > th .btn-default .fas,
.customTableCluster .table > tbody > tr > th .btn-default .fas,
.customTableCluster .table > tfoot > tr > th .btn-default .fas,
.customTableCluster .table > thead > tr > td .btn-default .fas,
.customTableCluster .table > tbody > tr > td .btn-default .fas,
.customTableCluster .table > tfoot > tr > td .btn-default .fas,
.customTableCluster .table > thead > tr > th .btn-default .far,
.customTableCluster .table > tbody > tr > th .btn-default .far,
.customTableCluster .table > tfoot > tr > th .btn-default .far,
.customTableCluster .table > thead > tr > td .btn-default .far,
.customTableCluster .table > tbody > tr > td .btn-default .far,
.customTableCluster .table > tfoot > tr > td .btn-default .far,
.customTableCluster .table > thead > tr > th .btn-default .glyphicon,
.customTableCluster .table > tbody > tr > th .btn-default .glyphicon,
.customTableCluster .table > tfoot > tr > th .btn-default .glyphicon,
.customTableCluster .table > thead > tr > td .btn-default .glyphicon,
.customTableCluster .table > tbody > tr > td .btn-default .glyphicon,
.customTableCluster .table > tfoot > tr > td .btn-default .glyphicon {
  color: #006beb;
}
.customTableCluster .table > thead > tr > th .btn-default:hover,
.customTableCluster .table > tbody > tr > th .btn-default:hover,
.customTableCluster .table > tfoot > tr > th .btn-default:hover,
.customTableCluster .table > thead > tr > td .btn-default:hover,
.customTableCluster .table > tbody > tr > td .btn-default:hover,
.customTableCluster .table > tfoot > tr > td .btn-default:hover {
  border: solid 1px #c0e096;
  background-color: white;
}
.customTableCluster .table > thead > tr > th .btn-xs,
.customTableCluster .table > tbody > tr > th .btn-xs,
.customTableCluster .table > tfoot > tr > th .btn-xs,
.customTableCluster .table > thead > tr > td .btn-xs,
.customTableCluster .table > tbody > tr > td .btn-xs,
.customTableCluster .table > tfoot > tr > td .btn-xs {
  position: absolute;
  top: 7px;
}
.customTableCluster .table > thead > tr > th .btn-sm,
.customTableCluster .table > tbody > tr > th .btn-sm,
.customTableCluster .table > tfoot > tr > th .btn-sm,
.customTableCluster .table > thead > tr > td .btn-sm,
.customTableCluster .table > tbody > tr > td .btn-sm,
.customTableCluster .table > tfoot > tr > td .btn-sm {
  position: absolute;
  top: 1.5px;
}
.customTableCluster .table > thead > tr > th,
.customTableCluster .table > tbody > tr > th,
.customTableCluster .table > tfoot > tr > th {
  font-weight: bold;
  letter-spacing: 1px;
}
.customTableCluster .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f6f7fa;
}
.customTableCluster .table-hover > tbody > tr:hover {
  background-color: #e3f1d1;
}
.customTableCluster .table-hover > tbody > tr.selected-row {
  background-color: #d8ebbd;
}
.customTableCluster .table-hover > tbody > tr.selected-row > td {
  border-bottom: solid 1px #567b24;
}
.customTableCluster.condensed td,
.customTableCluster.condensed th {
  padding: 3px 7px;
}
.enable-vscroll {
  overflow-y: auto;
  overflow-x: hidden;
}
.responsive-filter-group
  .dropdown-menu.dropdown-xs-only
  > li
  > .cmt-group_button {
  /* bnt */
  border-radius: 5px !important;
  display: inline-block;
  font-size: 0.9em;
  border: solid 1px #cfd8e3;
  padding: 5px 10px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.responsive-filter-group
  .dropdown-menu.dropdown-xs-only
  > li
  > .cmt-group_button.active {
  background-color: #c0e096;
  border: solid 1px #c0e096;
  color: #263241;
}
.responsive-filter-group
  .dropdown-menu.dropdown-xs-only
  > li
  > .cmt-group_button.active
  .text {
  color: #263241;
}
.responsive-filter-group
  .dropdown-menu.dropdown-xs-only
  > li
  > .cmt-group_button:hover {
  background-color: #cce6a9;
}
.ag-theme-bootstrap .ag-checkbox-input-wrapper {
  font-size: 16px !important;
  line-height: 16px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 16px !important;
  height: 16px !important;
  background-color: #fff !important;
  background-color: var(
    --ag-checkbox-background-color,
    var(--ag-background-color, #fff)
  ) !important;
  border-radius: 3px !important;
  display: inline-block !important;
  vertical-align: middle !important;
  flex: none !important;
}
.ag-theme-bootstrap .ag-checkbox-input-wrapper.ag-checked:after {
  font-family: FontAwesome !important;
  content: '\F00C' !important;
  /* color: #000; */
  color: var(
    --ag-checkbox-checked-color,
    var(--ag-foreground-color, white)
  ) !important;
  position: absolute;
  top: 0px;
  left: 0;
  pointer-events: none;
  background: none !important;
  background-color: #4f8901 !important;
  height: 19px !important;
  width: 19px !important;
}
.ag-theme-bootstrap .ag-checkbox-input-wrapper:after {
  content: '\F108';
  /* color: white; */
  color: var(
    --ag-checkbox-unchecked-color,
    var(--ag-foreground-color, white)
  ) !important;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  border: 1.5px solid #90a4c8 !important;
  border-radius: 3px !important;
}
.react-autosuggest__input {
  width: 100%;
  height: 34px;
  border: solid 1px #9fb1c7;
  background-color: white;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container--open {
  position: absolute;
  /*top: 100%;*/
  left: 0;
  z-index: 1000;
  display: block;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
}
.react-autosuggest__input {
  padding: 6px 12px;
}
.gm-style .gm-style-iw-t::after {
  height: 0px !important;
}
.ag-theme-bootstrap .ag-icon-menu {
  margin-bottom: 12px !important;
}
.grey-group_button.active {
  color: #006beb !important;
  font-weight: normal;
}
.grey-group_button {
  color: grey !important;
  font-weight: bold;
}

.react-checkbox-tree ol {
  margin: 0px -83px 0px 0px !important;
}

.frb-group {
  margin: 15px 0;
  min-width: 300px;
}

.frb ~ .frb {
  margin-top: 15px;
}

.frb input[type='radio']:empty,
.frb input[type='checkbox']:empty {
  display: none;
}

.frb input[type='radio'] ~ label:before,
.frb input[type='checkbox'] ~ label:before {
  font-family: FontAwesome;
  content: '\f096';
  position: absolute;
  top: 50%;
  margin-top: -11px;
  left: 15px;
  font-size: 22px;
}

.frb input[type='radio']:checked ~ label:before,
.frb input[type='checkbox']:checked ~ label:before {
  content: '\f046';
}

.frb input[type='radio'] ~ label,
.frb input[type='checkbox'] ~ label {
  position: relative;
  cursor: pointer;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 15px 0px;
  min-height: 75px;
}

.frb input[type='radio'] ~ label:focus,
.frb input[type='radio'] ~ label:hover,
.frb input[type='checkbox'] ~ label:focus,
.frb input[type='checkbox'] ~ label:hover {
  box-shadow: 0px 0px 3px #333;
}

.frb input[type='radio']:checked ~ label,
.frb input[type='checkbox']:checked ~ label {
  color: #fafafa;
}

.frb input[type='radio']:checked ~ label,
.frb input[type='checkbox']:checked ~ label {
  background-color: #f2f2f2;
}

.frb.frb-default input[type='radio']:checked ~ label,
.frb.frb-default input[type='checkbox']:checked ~ label {
  color: #333;
}

.frb.frb-primary input[type='radio']:checked ~ label,
.frb.frb-primary input[type='checkbox']:checked ~ label {
  background-color: #337ab7;
}

.frb.frb-success input[type='radio']:checked ~ label,
.frb.frb-success input[type='checkbox']:checked ~ label {
  background-color: #5cb85c;
}

.frb.frb-info input[type='radio']:checked ~ label,
.frb.frb-info input[type='checkbox']:checked ~ label {
  background-color: #5bc0de;
}

.frb.frb-warning input[type='radio']:checked ~ label,
.frb.frb-warning input[type='checkbox']:checked ~ label {
  background-color: #f0ad4e;
}

.frb.frb-danger input[type='radio']:checked ~ label,
.frb.frb-danger input[type='checkbox']:checked ~ label {
  background-color: #d9534f;
}

.frb input[type='radio']:empty ~ label span,
.frb input[type='checkbox']:empty ~ label span {
  display: block;
}

.frb input[type='radio']:empty ~ label span.frb-title,
.frb input[type='checkbox']:empty ~ label span.frb-title {
  font-size: 16px;
  font-weight: 700;
  margin: 5px 5px 5px 50px;
}

.frb input[type='radio']:empty ~ label span.frb-description,
.frb input[type='checkbox']:empty ~ label span.frb-description {
  font-weight: normal;
  font-style: italic;
  color: #999;
  margin: 5px 5px 5px 50px;
}

.frb input[type='radio']:empty:checked ~ label span.frb-description,
.frb input[type='checkbox']:empty:checked ~ label span.frb-description {
  color: #fafafa;
}

.frb.frb-default input[type='radio']:empty:checked ~ label span.frb-description,
.frb.frb-default
  input[type='checkbox']:empty:checked
  ~ label
  span.frb-description {
  color: #999;
}
div.device_scanner input[type='text'] {
  font-size: 16px !important;
}
.react-autosuggest__suggestions-container--open {
  margin-bottom: 35px;
  bottom: 0px;
}
ul.react-autosuggest__suggestions-list {
  display: flex;
  flex-direction: column-reverse;
}
div#interactive canvas {
  position: fixed;
}
.card-flex-center {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.articleWrapper {
  margin-bottom: 20px;
  width: 100%;
}
.articleWrapper .articleSearch-container {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  width: auto;
  border-radius: 0;
  margin: 0 0 15px 0;
  padding: 5px 4px 4px 5px;
  background-color: #cfd8e3;
  border: solid 1px white;
}
.fixedToolbar .toolbar-container {
  width: 100%;
  display: flex;
  align-items: center;
  /*justify-content: center;*/
  height: 34.5px;
  position: relative;
  z-index: 3;
}
.item .item-header .item-headline .item-headline-text {
  font-size: 22px !important;
}
.masonry {
  -webkit-column-gap: -1.5em;
  column-gap: 0.5em;
  font-size: 13px;
}
.ml-8 {
  margin-left: 8px;
}
.padding-5em {
  padding: 1.5em 0.8em !important;
}

.customNotification_bubble {
  max-width: 400px;
  max-height: 150px;
  padding-top: 5px;
  border-radius: 8px;
  margin-top: 100px;
  border: 0;
  z-index: 1000 !important;
  cursor: pointer;
}

.customNotification_bubble .notifyIcon {
  font-size: 36px;
  margin-top: 20px;
  margin-left: 5px;
  color: White;
}

.customNotification_bubble .title {
  font-size: 1.5em;
}

.customNotification_bubble .message {
  font-size: 12px;
}

.customNotification_bubble .closeIcon {
  cursor: pointer;
  color: white;
  height: 25px;
  width: 25px;
  position: relative;
  right: 10px;
  text-align: center;
  line-height: 20px;
}

.customNotification_bubble .closeIcon:hover {
  color: #cce6a9;
}

.customNotification_bubble:after {
  content: ' ';
  position: absolute;
  right: 30px;
  top: -15px;
  border-top: 0;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #ff9500;
}

#billPlanContainer .customTable {
  overflow: hidden;
}

div.dev-firmware-popup div.Select-menu-outer {
  max-height: 140px !important;
}
div.dev-firmware-popup div.Select-menu {
  max-height: 136px !important;
}

.asset-tooltip .tooltip-inner {
  width: 410px;
  min-width: 300px;
  max-width: 420px;
  height: auto;
  background-color: #fff;
  color: #000;
  padding: 0;
}

.asset-tooltip {
  font-size: 14px;
  width: 420px;
  min-width: 300px;
  max-width: 420px;
  height: auto;
  background-color: #fff;
  color: #000;
  opacity: 1 !important;
  border: 2px solid #8da8df;
}

.asset-tooltip .tooltip-arrow {
  display: none;
}
.prof-title-line-height {
  line-height: 34.5px !important;
  color: #567b24;
  fontsize: 15px !important;
}
/*************** react-modal-resizable-draggable ******************/
div#widgDrillContent > div.modal-header > .modal-title {
  width: 100%;
  cursor: move;
}

div#widgDrillDwnModal > div.modal-dialog > .modal-content {
  background: transparent;
}

div#widgDrillDwnModal > div.modal-dialog > .modal-content > .modal-resizable {
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #ebedee;
  border: 0;
  border-radius: 0.2em;
  box-shadow: 0 0 0 1px rgba(101, 108, 126, 0.5) inset,
    0 1px 3px 1px rgba(64, 64, 64, 0.5);
}

div#widgDrillDwnModal div.modal-dialog .modal-content .modal-resizable .modal-body {
  padding: 1em 1em 5em 1em;
}

div#widgDrillContent > .modal-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
}
.temp-tool-tip {
  width: 150px !important;
}
.close {
  border: 0px;
}
.ag-theme-alpine .ag-paging-panel {
  display: none;
}
.ag-header-cell-resize {
  background: #d0d3d7;
  width: 6px !important;
}
.gridAvlBoxStyle {
}
.showHideButtonsClass {
  background: #fff !important;
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 3px;
}
.showHideButtonsClass:hover {
  background: #a9d46e !important;
}

.ag-row-hover {
  /* putting in !important so it overrides the theme's styling as it hovers the row also */
  background-color: #B7D9F3 !important;
}
.modal-header {
  cursor: move;
}
.paginationContainer {
}
/*************** react-modal-resizable-draggable ******************/
@media only screen and (min-device-width: 281px) and (max-device-width: 767px) {
  .text-align-left {
    text-align: left !important;
  }
  .mw-xs-left-col {
    min-width: 128px;
  }
}
@media only screen and (min-device-width: 281px) and (max-device-width: 500px) {
  .mw-xs-left-title {
    width: 100px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gen-panel{
  margin-bottom: 10px;
  /* padding-top: 10px; */
  background-color: #f0f0f0;
  /* border: 1px solid; */
  border-radius: 6px;
}
