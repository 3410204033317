.pac-container {
    z-index: 2001 !important;
    /*background-color: #FFF !important;
    position: fixed !important;
   display: inline-block !important;
    float: left !important;*/
}

/*.modal{*/
/*    z-index: 2000 !important;*/
/*}*/
/*.modal-backdrop {*/
/*    z-index: 1000 !important;*/
/*}*/