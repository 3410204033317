@media (max-width: 767.98px) {
  .api-container {
    width: 100% !important;
  }
  #wrapper ~ .container-login {
    margin-top: 56px !important;
  }
  .topbar-breadcrumbs {
    /* padding: 0px !important; */
    /* margin-top: 105px !important; */
  }
  .topbar-breadcrumbs > div {
    position: absolute;
    /* width: 90% !important; */
  }
  .modal-content {
    width: 100% !important;
    height: 50% !important;
    max-height: 75% !important;
  }
  .topbar {
    /* position: absolute !important;*/
  }
  .lastAccessedStyle {
    transform: translate3d(-80px, 24px, 0px);
  }
  /* #wrapper ~ div {
  margin-top: 90px !important;
} */
  #wrapper ~ #myTabContent {
    margin-top: 10px !important;
  }
  .no-margin {
    margin-top: 0px !important;
  }

  .MGrid-table.tables {
    border: 0;
  }
  .MGrid-table.tables tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }
  .MGrid-table.tables td {
    text-align: left;
    width: 49%;
    display: inline-block;
    vertical-align: top;
  }
  /* .MGrid-table th, .MGrid-table td {
  display: table-cell;
  border: 1px solid #555;
  padding: 3px 6px;
} */
  /* table.tables.tbody {
  border-collapse: collapse;
  font-size: 13px;
} */
  .MGrid-table * a {
    text-decoration: none;
  }
  .MGrid-table td:before {
    content: attr(data-label);
    display: block;
    font-weight: bold;
    padding-right: 5px;
  }
  .MGrid-table.tables thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .MGrid-table {
    overflow-wrap: anywhere;
  }
  .org-container {
    width: 100% !important;
  }
}
@media (max-width: 950px) {
}
/* #wrapper ~ div {
  margin-top: 10px;
} */
.no-margin {
  margin-top: 0px !important;
}
.page-margin-top {
  margin-top: 100px !important;
}
#wrapper.enlarged .left.side-menu {
  padding-top: 0;
  z-index: 5;
  margin-left: -100%;
}

body.fixed-left .side-menu.left {
  bottom: 50px;
  height: 100%;
  margin-bottom: -70px;
  margin-top: 0;
  padding-bottom: 70px;
  position: fixed;
}

.side-menu {
  bottom: 0;
  top: 50px;
  width: 310px;
  padding: 40px 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: #404e67;
  position: fixed;
  z-index: 99;
  -webkit-box-shadow: 1px 0px 20px rgb(0 0 0 / 5%);
  box-shadow: 1px 0px 20px rgb(0 0 0 / 5%);
  overflow-x: hidden;
}

body {
  background: #f9f9f9;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  font-size: 13px;
  color: #000;
}

.topbar .topbar-left .logo {
  line-height: 50px;
}

.topbar-left .button-menu-mobile i {
  font-size: 20px !important;
  color: #fff;
  vertical-align: middle;
  min-width: 30px;
}

.fixed-left.fixed-left-void .fa-times {
  display: block;
}

img {
  vertical-align: middle;
  border-style: none;
}

.slimScrollDiv {
  height: auto !important;
}

.slimScrollDivStyle {
  position: fixed;
  width: auto;
}

#sidebar-menu {
  padding: 0px 15px 50px;
  width: 100%;
}

#sidebar-menu,
#sidebar-menu ul,
#sidebar-menu li,
#sidebar-menu a {
  border: 0;
  font-weight: normal;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
}

.slimScrollBar {
  background: rgb(158, 165, 171);
  width: 7px;
  position: absolute;
  top: 0px;
  opacity: 0.4;
  display: none;
  border-radius: 7px;
  z-index: 99;
  right: 1px;
  height: 693.965px;
}

.navi {
  text-align: right;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

li {
  text-align: -webkit-match-parent;
}

.navbar-custom .search {
  width: 25%;
  margin-right: 2%;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.app-search {
  position: relative;
}

form {
  display: block;
  margin-top: 0em;
}

li {
  text-align: -webkit-match-parent;
}

.topbar-breadcrumbs {
  background: #c1e4dc;
  margin: 0;
  padding: 5px 5px;
  box-shadow: 0px 0px 10px 1px #ddd;
  z-index: 9;
  font-size: 12px;
  color: #000;
  width: 100%;
  left: 0px;
  height: 35px;
}

.topbar-incompleteBar {
  background: #f2f59f;
  margin: 0;
  padding: 5px 5px;
  box-shadow: 0px 0px 10px 1px #ddd;
  z-index: 9;
  font-size: 12px;
  color: #000;
  width: 100%;
  left: 0px;
  height: 35px;
  line-height: 25px;
}

.content-page > .content {
  /*margin-top: 52px;*/ /* Modified by JK */
  padding: 0px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.topbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  background: #404e67;
}

.app-search .form-group button {
  position: absolute;
  top: 3.5px;
  left: 5px;
  display: block;
  color: #373935;
  font-size: 11px;
  border: none;
  background-color: transparent;
  /* border-right: 1px solid #000 !important; */
  padding-right: 10px !important;
}

.app-search .form-group .form-control,
.app-search .form-control:focus {
  border: none;
  padding-left: 35px;
  padding-right: 0;
  margin-right: 20px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
  color: #000;
  width: 100% !important;
  background: #fff;
  height: calc(1em + 0.75rem + 2px);
}

.app-search .form-group input {
  padding-left: 30px !important;
}

.sidebar-inner {
  height: 100%;
}

.side-menu-ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

/* .sidebar-inner-style {
  width: auto;
  height: 655px;
  overflow-x: auto;
  padding-bottom: 20px;
} */

#sidebar-menu,
#sidebar-menu ul,
#sidebar-menu li,
#sidebar-menu a {
  border: 0;
  font-weight: normal;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
}

#sidebar-menu {
  padding: 20px 4px 0px 20px;
  width: 300px;
  overflow-x: hidden;
}

body {
  background: #f9f9f9;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  font-size: 13px;
  color: #000;
}

.sidebar-inner h6 {
  color: #d8d8d8;
  margin-left: 46px;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

#sidebar-menu > ul > li > a {
  color: #fff;
  display: block;
  padding: 10px 10px;
  font-size: 14px;
}

#sidebar-menu > ul > li > a > span {
  vertical-align: middle;
  margin-left: 20px;
}

#sidebar-menu > ul > li > a:hover {
  color: #fff;
  background: #e0c642;
  border-radius: 5px;
}

.sidebar-inner hr {
  background: #999;
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
}

#sidebar-menu ul ul {
  display: none;
}

#sidebar-menu ul ul a {
  margin-top: 4px;
  color: #fff;
  display: block;
  padding: 15px 5px 15px 40px;
}

#sidebar-menu ul ul a:hover {
  /* color: #000; */
  color: #fff;
  background: #e0c642;
  border-radius: 5px;
}

#sidebar-menu > ul > li.nav-active > ul li a i {
  padding: 2px 10px 2px 0;
  float: left;
  width: 25px;
  text-align: center;
}

.navbar-custom {
  /* background-color: #fff; */
  border: none;
  -webkit-box-shadow: 1px 0px 20px rgb(0 0 0 / 5%);
  box-shadow: 1px 0px 20px rgb(0 0 0 / 5%);
  padding: 2px 10px 0px;
}

.navbar-custom .search {
  width: 25%;
  margin-right: 2%;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.notification-list {
  margin: 0px 10px !important;
}

.notification-list .noti-icon {
  font-size: 18px;
  padding: 0 10px;
  vertical-align: middle;
  color: #fff;
}

.notification-list .nav-link {
  padding: 0 5px;
  line-height: 50px;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.notification-list .noti-icon-badge {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 9px;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.dropdown-toggle {
  white-space: nowrap;
}

.author-list a,
.last-accessed a {
  cursor: pointer;
}

.nav-user {
  padding: 0px !important;
}

.nav-user img {
  /*height: 20px;
    width: 60px;
    border-radius: 7px;
    margin-right: 10px;*/

  width: 35px;
  margin-right: 10px;
  height: auto;
}

.user-info .user-name {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
}

.user-info {
  vertical-align: middle;
}

.author-list .fa-chevron-down {
  font-size: 12px;
  vertical-align: middle;
  color: #fff;
}

.dropdownstyle {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(-70px, 36px, 0px);
}
.dropdownstylemobile {
  position: center !important;
  will-change: transform !important;
  transform: translate3d(30%, 50px, 0px) !important;
}

.dropdownsearch {
  position: absolute !important;
  transform: translate3d(-69px, 21px, 0px) !important;
  top: 0px !important;
  left: 0px !important;
  will-change: transform !important;
}
.dropdown-toggle::after {
  content: none !important;
}

.fixed-left.fixed-left-void .navbar-custom a {
  color: #000 !important;
  font-weight: 500 !important;
}

.dropdown-item {
  padding: 9px 1rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.author-list .dropdown-item i,
.last-accessed .dropdown-item i {
  min-width: 20px;
  text-align: center;
}

.profile-dropdown i,
.last-accessed i {
  font-size: 17px;
  margin-right: 15px;
}

a label:hover {
  cursor: pointer;
}

.logo-right {
  margin-right: 25px !important;
}

.notification-list .noti-title {
  border-radius: 0.25rem 0.25rem 0 0;
  margin: -4px 0px 0px 0px;
  width: auto;
  padding: 12px 20px;
}

.notification-list .noti-title h5 {
  margin: 0;
  font-size: 14px;
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 auto 7px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.fixed-left.fixed-left-void .navbar-custom a {
  color: #000 !important;
  font-weight: 500 !important;
}

.notification-list .notify-item {
  padding: 15px 20px;
}

.notification-list .notify-item .notify-icon {
  float: left;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  margin-right: 15px;
  border-radius: 50%;
  color: #ffffff;
}

/* .bg-primary {
    background-color: #404e67 !important;
} */

.notification-list .notify-item .notify-details {
  margin-bottom: 0;
  overflow: hidden;
  margin-left: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notification-list .notify-item .notify-details span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}

.text-muted {
  color: #707070 !important;
}

.dropdown-menu-lg {
  width: 280px;
}

.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}
.dropdown-menu.show {
  display: block;
  z-index: 99999999;
}
.notification-list .dropdown-menu.dropdown-menu-right {
  -webkit-transform: translate(0, 25%);
  transform: translate(0, 25%);
  right: 0 !important;
  left: auto !important;
}

.notificationModalStyle {
  /* position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(-252px, 3px, 0px); */
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(0px, 25px);
}

.dropdown-menu-animated {
  display: block;
  visibility: hidden;
  opacity: 1;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  /* margin-bottom: 10px !important; */
}

.dropdown-menu {
  padding: 4px 0;
  font-size: 15px;
  -webkit-box-shadow: 1px 0px 20px rgb(0 0 0 / 5%);
  box-shadow: 1px 0px 20px rgb(0 0 0 / 5%);
  border-color: #eff3f6;
}

.slimscroll {
  /* max-height: 230px; */
  overflow-x: auto;
}

.notification-list .notify-all {
  border-radius: 0 0 0.25rem 0.25rem;
  margin: 0 0 -5px 0;
}

.fixed-left.fixed-left-void .navbar-custom a {
  color: #000 !important;
  font-weight: 500 !important;
}

.notification-list .notify-item .notify-icon img {
  margin-top: 4px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.notification-list .notify-item .notify-details b {
  font-weight: 500;
}

.lastAccessedStyle {
  position: absolute !important;
  transform: translate3d(170px, 30px, 0px);
  top: 0px !important;
  right: 0px !important;
  left: 0px !important;
  will-change: transform !important;
  width: fit-content !important;
}
.api-container * table {
  overflow-wrap: anywhere;
}

.topbar-breadcrumbs .dropdown-menu.dropdown-menu-right.show a {
  padding: 0.5rem 1rem !important;
}

.author-list .dropdown-item i,
.last-accessed .dropdown-item i {
  min-width: 20px;
  text-align: center;
}

.breadcrumbs {
  padding: 0.2rem 0px;
  display: inline-block;
}
.topbar-breadcrumbs > div {
  position: absolute;
  width: 99%;
}
.topbar-breadcrumbs {
  /* margin-top: 55px; */
}
.menu-arrow > i {
  position: absolute;
  right: 10%;
  top: 50%;
}

@media screen and (max-width: 1280px) {
  .sidebar-inner-style {
    width: auto;
    /* min-height: 600px !important; */
    overflow-y: auto !important;
    padding-bottom: 100px !important;
    /* height: 600px !important; */
    height: 90vh !important;
    overflow-x: hidden !important;
  }
}

@media screen and (min-width: 1300px) {
  .sidebar-inner-style {
    width: auto;
    /* min-height: 800px !important; */
    overflow-y: auto;
    padding-bottom: 20px !important;
    /* height: 625px !important; */
    height: 85vh !important;
    overflow-x: hidden !important ;
    
  }
}

.privacy a {
  color: #fff !important;
}





/* 
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
} */


/* scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 60px rgba(250, 250, 250, 9);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 60px #626262;
}

 ::-webkit-scrollbar-thumb:hover {
  background: #000;
  opacity:9
} 

