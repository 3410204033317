/*******dashboard ends**************/
/****** customTable begins here *****/
/******paginationContainer begins here************/
.paginationContainer {
  width: auto;
  border-radius: 0px;
  margin: 0;
  margin-top: -1px;
  padding: 5px 5px 4px 5px;
  background-color: #e7e6e2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: none;
}
.paginationContainer .paginationContainerGroup .form-control,
.paginationContainer .paginationContainerGroup .btn-default {
  box-shadow: none !important;
  border: none;
}
.paginationContainer .paginationContainerGroup .form-control {
  height: 32px;
  border: none;
}
.paginationContainer .paginationContainerGroup .paginationText {
  box-shadow: none;
  text-align: center;
  color: #263241;
  cursor: default;
}
.paginationContainer .paginationContainerGroup .paginationText:hover,
.paginationContainer .paginationContainerGroup .paginationText:focus {
  box-shadow: none;
}
.paginationContainer .paginationContainerGroup .input-group-addon {
  background-color: white;
  border: none;
  padding-left: 7px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.paginationContainer
  .paginationContainerGroup
  .btn-group-justified
  .btn-default:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.paginationContainer
  .paginationContainerGroup
  .btn-group-justified
  .btn-default:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.paginationContainer .input-group-addon {
  padding-left: 5px;
  padding-right: 5px;
}
.paginationContainer .input-group-addon .fa-search {
  font-size: 16px;
}
.paginationContainer .input-group-btn .btn {
  padding-left: 10px;
  padding-right: 10px;
}
.paginationContainer .input-group-btn:last-child .btn {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.paginationContainer .input-group-btn:first-child .btn {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.paginationContainer .btn {
  background-color: white;
  color: #263241;
}

.right-side {
  border-left: gray;
  border-left-width: 0.5px;
}
.paginationContainer .btn i.fa,
.paginationContainer .btn i.fas,
.paginationContainer .btn i.far,
.paginationContainer .btn i.glyphicon {
  top: 2px;
  color: #263241;
}
.paginationContainer .btn.grid-left-button:hover,
.btn.grid-right-button:hover {
  background-color: #edf0e6;
}

.paginationContainerGroup .btn-group-justified .btn:hover .fa-sort-alpha-down,
.paginationContainerGroup .btn-group-justified .btn:hover .fa-th,
.paginationContainerGroup .btn-group-justified .btn:hover .fa-download,
.paginationContainerGroup .btn-group-justified .btn:hover .fa-eraser {
  color: #fcf568;
}

.paginationContainer .btn:hover i.fa,
.paginationContainer .btn:hover i.fas,
.paginationContainer .btn:hover i.far,
.paginationContainer .btn:hover i.glyphicon {
  color: #263241;
}
/**********paginationContainer ends here********/
.ag-theme-alpine {
  font-family: 'Montserrat', sans-serif;
  /* !important; */
  background: #f9f9f9;
  font-weight: 500;
  font-size: 13px;
  color: black;
  padding-left: 5px;
  padding-right: 5px;
}
.ag-header-cell-text {
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-weight: 500;
  letter-spacing: .1em;
}
.modalPopupHeader {
  background: #fff;
  padding: 10px;
}
.showHideButtonsClass {
  background: #fff !important;
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 3px;
}
.showHideButtonsClass:hover {
  background: #a9d46e !important;
}
.modal-header {
  cursor: move;
}
.modal-content {
  border: 0px;
}
.page-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page-wrap .page-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.actionBar {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 7;
  height: calc(30px + 1px);
}
.actionBar.dark {
  background-color: #f2f2f2;
  height: 40px;
  padding-left: 5px;
}
.actionBar .left-section {
  z-index: 1;
  display: flex;
}
.actionBar .center-section {
  align-items: center;
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 0;
}

.actionBar .center-section .page-title-text {
  color: #555;
  letter-spacing: 1px;
}

.actionBar .right-section {
  z-index: 1;
  margin-left: auto;
  display: flex;
}

.actionBar .section-header-icon {
  width: 50px;
  text-align: center;
  color: #555;
}
.actionBar .minimize-column-btn:focus {
  outline: 0;
}

.actionBar .minimize-column-btn .icon .fa,
.actionBar .minimize-column-btn .icon .fal,
.actionBar .minimize-column-btn .icon .far,
.actionBar .minimize-column-btn .icon .fas {
  color: #000;
}

.actionBar .page-title {
  display: flex;
  align-items: center;
}

.actionBar .page-title .text {
  padding-left: 15px;
  color: #555;
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.actionBar .page-title .text.small {
  font-size: 0.9em;
}

.actionBar .page-title .count {
  padding-left: 10px;
  font-size: 0.9em;
}

.actionBar .section-title {
  padding-left: 0;
  padding-right: 10px;
}

.actionBar .section-title .text {
  padding-left: 0;
  font-size: 0.9em;
  color: #555;
}
.actionBar-wrap {
  z-index: 6;
  background-color: transparent;
  border-bottom: solid 1px #cfd8e3;
}

.actionBar-wrap.light {
  /*background-color: #f6f7fa*/
  background-color: #e3e8f0;
}

.actionBar-wrap.dark {
  background-color: #dfe5ec;
}

.actionBar-wrap.darker {
  background-color: transparent;
}

.actionBar-wrap .actionBar .page-title {
  padding-right: 10px;
}

.actionBar-wrap .actionBar .page-title .text {
  color: #555;
  letter-spacing: 0;
  font-weight: bold;
}

.actionBar-wrap .actionBar-actions {
  padding: 0;
}

.actionBar-wrap .actionBar-actions .btn {
  border-radius: 0;
  justify-content: center;
  border: none;
}

.actionBar-wrap .actionBar-actions .btn.border-none {
  border: none;
  outline: 0;
}

.actionBar-wrap .actionBar-actions .btn-light {
  background-color: transparent;
}

.actionBar-wrap .actionBar-actions .btn-light.active {
  background-color: #9dd6ff;
}

.actionBar-wrap .actionBar-actions .btn-primary {
  border: none;
  background-color: transparent;
}

.actionBar-wrap .actionBar-actions .btn-primary .icon .fa,
.actionBar-wrap .actionBar-actions .btn-primary .icon .fal,
.actionBar-wrap .actionBar-actions .btn-primary .icon .far,
.actionBar-wrap .actionBar-actions .btn-primary .icon .fas,
.actionBar-wrap .actionBar-actions .btn-primary .text {
  color: #000;
}

.actionBar-wrap .actionBar-actions .btn-primary:hover {
  background-color: #c1ccde;
}

.actionBar-wrap .actionBar-actions .btn-selected {
  background-color: #106ebe;
}

.actionBar-wrap .actionBar-actions .back-button {
  border: none;
  background-color: transparent;
  color: #000;
}

.actionBar-wrap .actionBar-actions .back-button .text {
  color: #000;
  font-size: 0.9em;
}

.actionBar-wrap .actionBar-actions .back-button .fa,
.actionBar-wrap .actionBar-actions .back-button .fal,
.actionBar-wrap .actionBar-actions .back-button .far,
.actionBar-wrap .actionBar-actions .back-button .fas {
  color: #000;
  position: relative;
  top: -0.5px;
}

.actionBar-wrap .actionBar-actions .back-button:hover {
  background-color: #c1ccde;
}
.plusIcon {
  font-size: 25px;
  color: blue;
}
.orgHide {
  display: none;
}
.orgShow {
  display: block;
}
.checkBox {
  width: 18px;
  height: 20px;
}
.Toastify__toast--error {
  border: 1px solid #eb5757;
  border-radius: 5px !important;
  background: #fae1e2 !important;
}
.Toastify__toast--error::after {
  /*content : url('../assets/images/svg/closeToast.svg'); // Your svg Path*/
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast--error::before {
  /*content: url("../assets/images/svg/errorToast.svg");// Your svg Path*/
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  border-radius: 5px !important;
  background: #f0f9fa !important;
}
.Toastify__toast--success::before {
  /*content: url("../assets/images/svg/successToast.svg");// Your svg Path*/
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success::after {
  /*content : url('../assets/images/svg/closeToast.svg');// Your svg Path*/
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast--warning {
  border: 1px solid #e78326 !important;
  border-radius: 5px !important;
  background: #fadfc5 !important;
}
.Toastify__toast--warning::before {
  /*content: url("../assets/images/svg/warnToast.svg");// Your svg Path*/
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--warning::after {
  /*content : url('../assets/images/svg/closeToast.svg'); // Your svg Path*/
  position: absolute;
  color: #e78326;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast-body {
  color: #444c63;
  font-size: 14px;
  padding-left: 5px;
  line-height: 20px;
  padding: 0px;
  padding-top: 5px;
  width: 100%;
  font-weight: 400;
  margin-left: 5px !important;
}
.Toastify__toast > button > svg {
  display: none;
}
.Toastify__toast-theme--light {
  background: #ebf7f5;
}
.accordBody {
  padding: 0px;
  padding-bottom: 10px;
}
.accordHeader {
  padding: 10px;
  font-size: 13px;
}
.gridHeight {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.gridpadding {
  padding-left: 5px;
  padding-right: 5px;
}
.box {
  border: 1px solid #000;
  text-align: center;
}
.boxShadow {
  /*box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px*/
}
.hyperlink {
  color: #0c63e4 !important;
  cursor: pointer;
  text-decoration: none;
}
.grid-right-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.grid-left-button {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ag-theme-alpine{
    font-family: Montserrat,sans-serif;
}

/*.container {
    background-color: rgb(240, 240, 240);
    margin: auto;
    width: calc(95%);

    border: none;
    border-radius: 4px;
}

#checkout-form {
    position: relative;
    width: calc(95%);
    margin: auto;
    padding: 10px;
}

#checkout-form label {
    display: block;
    min-height: 25px;

    font-size: 15px;
    font-weight: 500;
    margin: 5px 0;
    padding: 0;
    color: red;
}

#card-number, #card-cvv, #card-expiry {
    background-color: #FFF;
    display: block;
    border-radius: 2px;
    border: 1px solid rgba(200, 200, 200, 1);
    padding: 14px 60px 13px 20px;
    margin: auto;
    transition: all 100ms ease-out;
}


#card-number {
    background-image: none;

    background-origin: content-box;
    background-position: calc(100% + 40px) center;
    background-repeat: no-repeat;
    background-size: contain;
}


.btn {
    vertical-align: top;
}

.btn {
    position: relative;
    border: none;
    border-radius: 4px;
    background-color: rgba(120, 71, 181, 1);
    color: rgba(255, 255, 255, 1);
    display: inline-block;
    transition: all 100ms ease-out;
    padding: 11px 25px;
}

.btn:hover, .btn:active {
    background-color: rgba(69, 36, 89, 1);
}

.btn:active {
    box-shadow: inset 0 0 35px rgba(0, 0, 0, 0.3);
}

.btn:disabled {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(120, 71, 181, 1);
    color: rgba(120, 71, 181, 1);
}


#feedback {
    position: relative;
    left: 15px;
    display: inline-block;
    background-color: transparent;
    border: 0px solid rgba(200, 200, 200, 1);
    border-radius: 4px;
    transition: all 100ms ease-out;
    padding: 11px;
}

#feedback.error {
    color: red;
    border: 1px solid;
}

#feedback.success {
    color: seagreen;
    border: 1px solid;
}*/

.ag-theme-alpine .ag-header-cell {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.ag-theme-alpine {
  /* disable all borders */
  --ag-borders-none: var(--ag-borders-none) !important;
  /* --ag-borders: none !important; */
  /* then add back a border between rows */
  /* --ag-borders-row: dashed 1px ;
    --ag-row-border-color: rgb(150, 150, 200); */
}

.ag-theme-alpine .ag-cell {
  padding-left: 5px;
}
.ag-header-cell{
  background-color: #E4E4E4;
}
.ag-selection-checkbox {
  padding-left: 0px !important;
  left: 0px !important;
}
